import { atom } from 'recoil'

export const clientDataAtom = atom({
  key: 'clientData',
  default: false
})

export const clientDataGridAtom = atom({
  key: 'clientDataGrid',
  default: {
    docs: [],
    pages: {},
    limit: 25,
    loading: true,
    page: 0,
    totalDocs: 0
  }
})

export const scheduleEventsAtom = atom({
  key: 'scheduleEvents',
  default: false
})

export const scheduleFilterAtom = atom({
  key: 'scheduleFilter',
  default: false
})

export const usersDataGridAtom = atom({
  key: 'usersDataGrid',
  default: {
    docs: [],
    pages: {},
    limit: 25,
    loading: true,
    page: 0,
    totalDocs: 0
  }
})

export const contractDataGridAtom = atom({
  key: 'contractDataGrid',
  default: {
    docs: [],
    pages: {},
    loading: true,
    page: 0,
    totalDocs: 0
  }
})

export const contractLimitAtom = atom({
  key: 'contractLimit',
  default: 25
})

export const contractFilterModelAtom = atom({
  key: 'contractFilterModel',
  default: {
    items: [],
    quickFilterValues: [],
    quickFilterLogicOperator: 'and'
  }
})

export const contractSortModelAtom = atom({
  key: 'contractSortModel',
  default: [{ field: 'documentedAt', sort: 'desc' }]
})

export const clientFilterModelAtom = atom({
  key: 'clientFilterModel',
  default: false
})

export const userFilterModelAtom = atom({
  key: 'userFilterModelAtom',
  default: {
    items: [],
    quickFilterValues: [],
    quickFilterLogicOperator: 'and'
  }
})

export const invoiceDataGridAtom = atom({
  key: 'invoiceDataGrid',
  default: {
    docs: [],
    pages: {},
    loading: true,
    page: 0
  }
})
export const invoiceLimitAtom = atom({
  key: 'invoiceLimit',
  default: 25
})

export const invoiceFilterModelAtom = atom({
  key: 'invoiceFilterModel',
  default: {
    items: [],
    quickFilterValues: [],
    quickFilterLogicOperator: 'and'
  }
})

export const invoiceSortModelAtom = atom({
  key: 'invoiceSortModel',
  default: [{ field: 'dueAt', sort: 'desc' }]
})

export const locationFilterAtom = atom({
  key: 'locationFilterAtom',
  default: []
})

export const locationListAtom = atom({
  key: 'locationListAtom',
  default: []
})

export const scheduleAutocompleteOptionsAtom = atom({
  key: 'scheduleAutocompleteOptions',
  default: false
})

export const scheduleAutocompleteFilterAtom = atom({
  key: 'scheduleAutocompleteFilter',
  default: []
})
