import { Grid } from '@mui/material'
import React from 'react'
import { getClients, getUsersData } from '../services'
import UsersDataTable from '../components/UsersDataTable'
import { usersColumn } from '../data/userData'
import NewUserModal from '../components/NewUserModal'
import MobileTitle from '../components/MobileTitle'
import { logError } from '../services/sentry'
import { useRecoilState } from 'recoil'
import { companyListAtom } from '../recoil/company'
import usePrevious from '../hooks'
import { useSnackbar } from 'notistack'
import { snackBarFailure } from '../theme'
import { handleAxiosError } from '../utils'

const UsersPage = () => {
  const [companyList, setCompanyList] = useRecoilState(companyListAtom)
  const triggerRefresh = () => {
    setRefresh(prev => !prev)
  }
  const { enqueueSnackbar } = useSnackbar()
  const [refresh, setRefresh] = React.useState(false)
  const previousRefresh = usePrevious(refresh)

  React.useEffect(() => {
    const getData = async () => {
      try {
        const data = await getClients()
        setCompanyList(data)
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
      }
    }
    if (!companyList) {
      getData()
    }
  }, [])

  return (
    <Grid spacing={4} container>
      <MobileTitle title="Users" />
      <Grid textAlign="left" item xs={12}>
        <NewUserModal triggerRefresh={triggerRefresh} />
      </Grid>
      <Grid mb={12} item xs={12}>
        <UsersDataTable
          previousRefresh={previousRefresh}
          refresh={refresh}
          triggerRefresh={triggerRefresh}
          service={getUsersData}
          columns={usersColumn}
        />
      </Grid>
    </Grid>
  )
}

export default UsersPage
