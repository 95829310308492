/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React from 'react'
import parse from 'html-react-parser'
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PersonIcon from '@mui/icons-material/Person'
import makeStyles from '@mui/styles/makeStyles'
import Link from './Link'

import clsx from 'clsx'
import FeedbackModal from './FeedbackModal'
import { LoadingWrapper } from './LoadingWrapper'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    gap: theme.spacing(2.5),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    height: 53,
    borderRadius: 3,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.border,
    '& .MuiListItemText-root': {
      whiteSpace: 'nowrap'
    },
    '& .MuiListItem-gutters': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5)
    }
  },
  link: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center'
  },
  profile: {
    width: 30,
    height: 30,
    marginRight: 8
  },
  profileName: {
    flex: 'none',
    fontWeight: 600,
    color: '#0F1222'
  },
  sideBarName: {
    textAlign: 'left',
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 600,
      fontSize: 20,
      color: '#0F1222'
    },
    '& .MuiListItemText-secondary': {
      color: '#A4A7B6',
      fontSize: 16
    }
  },
  sidebarProfile: {
    width: 51,
    height: 54,
    marginRight: 16,
    '& img': {
      padding: 2
    }
  },
  sidebarLink: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16
  },
  icon: {
    // marginRight: 10
  },
  sidebarIcon: {
    marginRight: theme.spacing(2),
    height: 24,
    width: 54,
    justifyContent: 'center',
    alignItems: 'center'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    '& .MuiButton-label': {
      fontSize: 14
    },
    width: 'auto'
  },
  grid: {
    background: theme.palette.background.paper,
    textAlign: 'left',
    position: 'relative',
    padding: theme.spacing(4, 3, 0, 3)
  },
  avatar: {
    width: 50,
    height: 50
  },
  smallAvatar: {
    width: 25,
    height: 25
  },
  afterHours: {
    '& .MuiListItemText-secondary': {
      color: '#A4A7B6',
      fontWeight: 600
    }
  },
  noPaddingLeft: {
    paddingLeft: 0
  },
  arrowIcon: {
    verticalAlign: 'sub',
    marginLeft: theme.spacing(0.5)
  },
  popperTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 21,
    color: '#263238'
  },
  closeIcon: {
    color: '#263238'
  },
  select: {
    width: 247,
    display: 'flex',
    '& .MuiSelect-select': {
      display: 'flex'
    },
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: 'none'
    }
  },
  selectLogo: {
    placeSelf: 'center'
  },
  hidden: {
    visibility: 'hidden'
  },
  spanData: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}))

export const TraderContactBar = ({ name, imageId, email, loading, phone }) => {
  const classes = useStyles()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid position="relative" container>
      <List className={classes.list}>
        <LoadingWrapper visible={loading}>
          {name && desktop && (
            <ListItem className={classes.listItem}>
              {imageId && (
                <ListItemIcon className={classes.icon}>
                  <Avatar
                    className={clsx(classes.smallAvatar, classes.selectLogo)}
                    alt="logo"
                    src={
                      process.env.REACT_APP_API_URL +
                      '/Media/tenantMedia/' +
                      imageId
                    }
                  />
                </ListItemIcon>
              )}
              <span className={classes.profileName}>{name}</span>
            </ListItem>
          )}
          {name && !desktop && imageId && (
            <ListItemIcon className={classes.icon}>
              <Avatar
                className={clsx(classes.smallAvatar, classes.selectLogo)}
                alt="logo"
                src={
                  process.env.REACT_APP_API_URL +
                  '/Media/tenantMedia/' +
                  imageId
                }
              />
            </ListItemIcon>
          )}
          <ListItem className={classes.listItem} color="primary">
            {phone && (
              <Link className={classes.link} href={`tel:+${phone}`}>
                <ListItemIcon className={classes.icon}>
                  <PhoneSharpIcon color="primary" />
                </ListItemIcon>
                {desktop && <span className={classes.spanData}>{phone}</span>}
              </Link>
            )}
          </ListItem>
          {email && (
            <ListItem className={classes.listItem}>
              <Link className={classes.link} href={`mailto:${email}`}>
                <ListItemIcon className={classes.icon}>
                  <MailOutlineIcon color="primary" />
                </ListItemIcon>
                <span className={classes.spanData}>{desktop && email} </span>
              </Link>
            </ListItem>
          )}
          <ListItem className={classes.listItem}>
            <FeedbackModal />
          </ListItem>
        </LoadingWrapper>
      </List>
    </Grid>
  )
}

const SelectSidecard = ({ email, loading, jobTitle, name, phone, imageId }) => {
  const classes = useStyles()

  // const handleChange = e => {
  //   const arr = data.filter(x => x?.id === e.target.value)
  // }

  return (
    <Card className={classes.grid}>
      <LoadingWrapper visible={loading}>
        <CardContent className={classes.sidebar}>
          <ListItem>
            <ListItemIcon
              // style={{ visibility: 'hidden' }}
              className={classes.sidebarIcon}>
              {imageId ? (
                <Avatar
                  className={clsx(classes.avatar, classes.selectLogo)}
                  alt="logo"
                  src={
                    process.env.REACT_APP_API_URL +
                    '/Media/tenantMedia/' +
                    imageId
                  }
                />
              ) : (
                <PersonIcon color="primary" />
              )}
              {/* <PhoneSharpIcon color="primary" /> */}
            </ListItemIcon>
            <ListItemText
              className={classes.sideBarName}
              primary={name}
              secondary={jobTitle}
            />
          </ListItem>
          {phone && (
            <ListItem color="primary">
              <ListItemIcon className={classes.sidebarIcon}>
                <PhoneSharpIcon color="primary" />
              </ListItemIcon>
              <Link className={classes.sidebarLink} href={`tel:+${phone}`}>
                {phone}
              </Link>
            </ListItem>
          )}
          {email && (
            <ListItem>
              <ListItemIcon className={classes.sidebarIcon}>
                <MailOutlineIcon color="primary" />
              </ListItemIcon>
              <Link className={classes.sidebarLink} href={`mailto:${email}`}>
                {email}
              </Link>
            </ListItem>
          )}
          <ListItem>
            <FeedbackModal />
          </ListItem>
        </CardContent>
      </LoadingWrapper>
    </Card>
  )
}

SelectSidecard.propTypes = {
  address: PropTypes.string,
  name: PropTypes.string,
  jobtitle: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  loading: PropTypes.bool
}

export { SelectSidecard }

const GenericSidecard = ({
  afterHoursPhone,
  img,
  address,
  loading,
  name,
  phone,
  email,
  feedback,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.grid}>
      <CardContent className={classes.sidebar}>
        <ListItem className={!img ? classes.noPaddingLeft : ''}>
          {img && (
            <Avatar
              className={classes.sidebarProfile}
              alt="profile"
              src={img}
            />
          )}
          <ListItemText
            className={classes.sideBarName}
            primary={name}
            secondary={parse(address)}
          />
        </ListItem>
        {phone && (
          <ListItem color="primary">
            <ListItemIcon className={classes.sidebarIcon}>
              <PhoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              {phone?.map((x, i) => (
                <Box key={i}>
                  <Link className={classes.sidebarLink} href={`tel:+${x}`}>
                    {x}
                  </Link>
                </Box>
              ))}
            </ListItemText>
          </ListItem>
        )}
        {email && (
          <ListItem>
            <ListItemIcon className={classes.sidebarIcon}>
              <MailOutlineIcon color="primary" />
            </ListItemIcon>
            <Link className={classes.sidebarLink} href={`mailto:${email}`}>
              {email}
            </Link>
          </ListItem>
        )}
        {afterHoursPhone && (
          <>
            <ListItem sx={{ pb: 0, mt: 2 }}>
              <ListItemIcon
                className={clsx(classes.sidebarIcon, classes.hidden)}>
                <MailOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                className={classes.afterHours}
                secondary="After Hours:"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.sidebarIcon}>
                <PhoneSharpIcon color="primary" />
              </ListItemIcon>
              <ListItemText>
                {afterHoursPhone?.map((x, i) => (
                  <Box key={i}>
                    <Link className={classes.sidebarLink} href={`tel:+${x}`}>
                      {x}
                    </Link>
                  </Box>
                ))}
              </ListItemText>
            </ListItem>
          </>
        )}
        {feedback && (
          <ListItem>
            <FeedbackModal />
          </ListItem>
        )}
      </CardContent>
    </Card>
  )
}

GenericSidecard.propTypes = {
  afterHoursPhone: PropTypes.array,
  img: PropTypes.string,
  address: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.array,
  email: PropTypes.string,
  feedback: PropTypes.bool
}

export { GenericSidecard }

export default TraderContactBar
