import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
  editNotificationPreferences,
  getNotificationPreferences,
  getUsersData
} from '../services/index'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../recoil/user'
import { snackBarFailure } from '../theme'
import { useSnackbar } from 'notistack'
import { logError } from '../services/sentry'
import { LoadingWrapper } from './LoadingWrapper'
import { handleAxiosError } from '../utils'
import Spacer from './Spacer'
import { ReactComponent as SearchSvg } from '../assets/search.svg'

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: theme.spacing(6)
  },
  title: {
    fontSize: 18,
    color: '#0f1222'
  },
  gridRows: {
    margin: theme.spacing(2, 0)
  },
  description: {
    fontSize: 16,
    color: '#535875'
  },
  divider: {
    width: '100%'
  }
}))

const NotificationPreferences = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const user = useRecoilValue(userAtom)
  const [loading, setLoading] = React.useState(false)
  const [userOptionsLoading, setUserOptionsLoading] = React.useState(false)
  const [notificationArray, setNotificationArray] = React.useState([])
  const [chosenUser, setChosenUser] = React.useState('')
  const [userList, setUserList] = React.useState([])
  const [notificationType, setNotificationType] =
    React.useState('emailNotification')

  React.useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const dataArray = await getNotificationPreferences(chosenUser)
        setNotificationArray(dataArray)
        setLoading(false)
      } catch (error) {
        logError(error)
        setLoading(false)
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
      }
    }
    getData()
  }, [chosenUser])

  React.useEffect(() => {
    const getUsersList = async () => {
      try {
        setUserOptionsLoading(true)
        const { docs } = await getUsersData()
        setUserList(docs)
        setUserOptionsLoading(false)
      } catch (error) {
        setUserOptionsLoading(false)
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
      }
    }
    if (user?.isAdmin) {
      getUsersList()
    }
  }, [])

  const handleToggleChange = async (event, clientName, location, id) => {
    const checked = event.target.checked
    try {
      await editNotificationPreferences(id, {
        email: chosenUser,
        [`${notificationType}`]: checked
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
      logError(error)
    }
    let newArray = [...notificationArray]
    const clientIndex = newArray?.findIndex(object => {
      return object.clientName === clientName
    })
    const locationIndex = newArray?.[clientIndex]?.locations?.findIndex(
      object => {
        return object.locationCode === location
      }
    )
    const notificationIndex = newArray?.[clientIndex]?.locations?.[
      locationIndex
    ]?.notifications.findIndex(
      object => object.notificationType === event?.target?.name
    )

    newArray[clientIndex].locations[locationIndex].notifications[
      notificationIndex
    ][`${notificationType}`] = checked

    setNotificationArray(newArray)
  }

  const handleUserChange = e => {
    setChosenUser(e?.email)
  }

  const handleNotificationChange = e => {
    setNotificationType(e.target.value)
  }

  return (
    <Box position="relative">
      <LoadingWrapper visible={loading}>
        <Grid spacing={2} container>
          <Grid item xs={12}>
            <Typography
              mb={3}
              textAlign="left"
              className={classes.heading}
              variant="h5">
              Notifications
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container item spacing={2} xs={12}>
              {user?.isAdmin && (
                <Grid xs={12} xl={4} md={6} item>
                  <Autocomplete
                    disablePortal
                    getOptionLabel={option => option.email}
                    sx={{
                      '& .MuiFormControl-root': {
                        marginTop: 0
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        margin="normal"
                        {...params}
                        variant="outlined"
                        placeholder="User"
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleUserChange(newValue)
                    }}
                    loading={userOptionsLoading}
                    options={userList}
                  />
                </Grid>
              )}
              <Grid xs={12} xl={4} md={6} item>
                <TextField
                  sx={{ mt: 0 }}
                  onChange={handleNotificationChange}
                  fullWidth
                  label="Type"
                  select
                  defaultValue="emailNotification"
                  margin="normal"
                  variant="outlined"
                  placeholder="User">
                  <MenuItem value="emailNotification">Email</MenuItem>
                  <MenuItem value="portalNotification">Notification</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {user?.isAdmin && !chosenUser ? (
              <Box mx="auto" mt={7} maxWidth={433}>
                <SearchSvg />
                <Typography
                  mt={2}
                  gutterBottom
                  sx={{ fontWeight: 'bold' }}
                  variant="h4">
                  Select a user to configure their notifications.
                </Typography>
              </Box>
            ) : !notificationArray?.length && !loading ? (
              <Box mt={16} maxWidth={370} mx="auto">
                <Typography mb={2} fontWeight="bold" variant="h4">
                  This user isn't assigned to a client yet.
                </Typography>
                <Typography color="text.gray" fontSize={18}>
                  Please assign them to a client to be able to configure their
                  notifications.
                </Typography>
              </Box>
            ) : (
              notificationArray?.map((client, i) => (
                <Box mt={2} key={i}>
                  <Typography fontWeight={600} fontSize={24} textAlign="left">
                    {client?.clientName}
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        {client?.type === 'Customer' ? (
                          <TableRow>
                            <TableCell>
                              <Typography>Location</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography textAlign="center">
                                Invoice Due Soon
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography textAlign="center">
                                Invoice Overdue
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography textAlign="center">
                                New Contract
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography textAlign="center">
                                New Invoice
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography textAlign="center">
                                Schedule Change
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Typography>Location</Typography>
                            </TableCell>
                            <TableCell colSpan={1}>
                              <Typography textAlign="center">
                                New Contract
                              </Typography>
                            </TableCell>
                            <TableCell colSpan={1}>
                              <Typography textAlign="center">
                                Schedule Change
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {client?.locations?.map((location, i) => (
                          <TableRow key={i}>
                            <TableCell colSpan={1}>
                              {location?.locationCode}
                            </TableCell>
                            {client?.type === 'Supplier' && (
                              <TableCell colSpan={2}></TableCell>
                            )}
                            {location?.notifications.map((x, i) => (
                              <TableCell colSpan={1} align="center" key={i}>
                                <Checkbox
                                  name={x.notificationType}
                                  checked={x[`${notificationType}`]}
                                  onChange={e =>
                                    handleToggleChange(
                                      e,
                                      client?.clientName,
                                      location?.locationCode,
                                      x?.notificationPreferenceId
                                    )
                                  }
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Spacer m={6} />
                </Box>
              ))
            )}
          </Grid>
        </Grid>
      </LoadingWrapper>
    </Box>
  )
}

export default NotificationPreferences
