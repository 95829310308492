import withStyles from '@mui/styles/withStyles'
import LoadingButton from '@mui/lab/LoadingButton'

export default withStyles(props => ({
  root: ({ variant }) => ({
    ...(variant !== 'link' &&
      variant !== 'text' &&
      variant !== 'outlined' && {
        padding: '10px 60px',
        borderRadius: 37.5,
        fontWeight: 'bold',
        fontSize: 'larger'
      })
  }),
  label: {
    fontWeight: 700,
    fontSize: 17,
    lineHeight: 1.25
  }
}))(LoadingButton)
