import React, { useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import { Typography, Box, Grid, useMediaQuery, Tooltip } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { eventNotFoundAtom, selectedEventAtom } from '../recoil/schedule/atom'
import { styled } from '@mui/system'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { getCustomerContractLines, getSupplierContractLines } from '../services'
import { useTheme } from '@mui/styles'
import { snackBarFailure } from '../theme'
import { useSnackbar } from 'notistack'
import { logError } from '../services/sentry'
import { LoadingWrapper } from './LoadingWrapper'
import { PropTypes } from 'prop-types'
import { handleAxiosError, isToday } from '../utils'
import {
  locationFilterAtom,
  locationListAtom,
  scheduleAutocompleteFilterAtom,
  scheduleAutocompleteOptionsAtom,
  scheduleEventsAtom,
  scheduleFilterAtom
} from '../recoil/data'

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '--fc-button-bg-color': theme.palette.primary.main,
  '--fc-button-hover-bg-color': theme.palette.primary.dark,
  padding: theme.spacing(3, 1),
  '& .event-tbd': {
    backgroundColor: theme.palette.warning.main
  },
  '& .fc .fc-toolbar-title': {
    fontSize: '1rem'
  },
  '& .fc .fc-daygrid-day-number': {
    padding: '0px 6.5px'
  },
  '& .fc .fc-daygrid-day.fc-day-today': {
    backgroundColor: 'transparent',
    position: 'relative',
    '& .fc-daygrid-day-number': {
      background: theme.palette.primary.main,
      color: '#fff',
      borderRadius: '50%'
    }
  },
  '& .event-finalized': {
    backgroundColor: theme.palette.primary.light
  },
  '.fc-theme-standard td': {
    border: '10px solid #fff'
  },
  '& .fc-list-event-dot, .fc-list-event-time, .fc-list-event-graphic': {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3)
  }
}))

const Schedule = ({ home, handleReceivedData, calendarRef }) => {
  const locationFilter = useRecoilValue(locationFilterAtom)
  const autocompleteOptions = useRecoilValue(scheduleAutocompleteOptionsAtom)
  const setAutocompleteFilter = useSetRecoilState(
    scheduleAutocompleteFilterAtom
  )
  const setLocationList = useSetRecoilState(locationListAtom)
  const theme = useTheme()
  const [events, setEvents] = useRecoilState(scheduleEventsAtom)
  const [filteredEvents, setFilteredEvents] = useRecoilState(scheduleFilterAtom)
  const [loading, setLoading] = React.useState(false)
  const [selectedEvent, setSelectedEvent] = useRecoilState(selectedEventAtom)
  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const { enqueueSnackbar } = useSnackbar()
  const { pathname } = useLocation()
  const { id } = useParams()
  const userType = pathname.includes('Suppliers') ? 'supplier' : 'customer'

  const locationProp = pathname.includes('Suppliers')
    ? 'pickupLocation'
    : 'dropoffLocation'

  useEffect(() => {
    let calendarApi = calendarRef?.current?.getApi()
    if (desktop) {
      return calendarApi?.changeView('dayGridMonth')
    }
    return calendarApi?.changeView('listWeek')
  }, [calendarRef, desktop])

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        if (pathname.includes('Suppliers')) {
          const response = await getSupplierContractLines(id)
          const mappedResponse = response.map(x => ({ ...x, end: x.start }))
          const eventsToday = mappedResponse.filter(x => isToday(x?.date))
          if (eventsToday?.length && !selectedEvent?.title) {
            const { title, ...extendedProps } = eventsToday[0]
            setSelectedEvent({ title, extendedProps })
          } else {
            setSelectedEvent(false)
          }
          const locationList = [
            ...new Set(mappedResponse?.map(x => x[locationProp]))
          ]
          await setEvents(mappedResponse)
          await setFilteredEvents(mappedResponse)
          await setLocationList(locationList)
          await handleReceivedData()
          setLoading(false)
        } else if (pathname.includes('Customers')) {
          const response = await getCustomerContractLines(id)
          const mappedResponse = response.map(x => ({ ...x, end: x.start }))
          const eventsToday = mappedResponse.filter(x => isToday(x?.date))
          if (eventsToday?.length && !selectedEvent?.title) {
            const { title, ...extendedProps } = eventsToday[0]
            setSelectedEvent({ title, extendedProps })
          } else {
            setSelectedEvent(false)
          }
          const locationList = [
            ...new Set(mappedResponse?.map(x => x[locationProp]))
          ]
          await setEvents(mappedResponse)
          await setFilteredEvents(mappedResponse)
          await setLocationList(locationList)
          await handleReceivedData()
          setLoading(false)
        }
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
        setLoading(false)
      }
    }
    if (!events) {
      getData()
    }
  }, [id, pathname])

  React.useEffect(() => {
    if (locationFilter?.length && autocompleteOptions && events) {
      const filtered = events?.filter(x =>
        locationFilter?.includes(x?.[locationProp])
      )
      const filteredOptions = autocompleteOptions?.filter(x =>
        locationFilter?.includes(x?.extendedProps?.[locationProp])
      )
      setFilteredEvents(filtered)
      setAutocompleteFilter(filteredOptions)

      if (selectedEvent?.extendedProps) {
        const eventInFilter = filtered.find(
          x =>
            x[`${userType}ContractNumber`] ===
            selectedEvent?.extendedProps[`${userType}ContractNumber`]
        )
        if (!eventInFilter) {
          setSelectedEvent({})
        }
      }
    } else {
      setFilteredEvents(events)
      setAutocompleteFilter(autocompleteOptions)
    }
  }, [locationFilter])

  let navigate = useNavigate()
  const setNotFound = useSetRecoilState(eventNotFoundAtom)

  const handleEventClick = async clickInfo => {
    const { event } = clickInfo
    await setSelectedEvent({ ...event?._def, ...event?._instance })
    setNotFound(false)
    let calendarApi = await calendarRef?.current?.getApi()
    await calendarApi?.gotoDate(
      event?._def?.extendedProps?.dropoffAt ||
        event?._def?.extendedProps?.pickupAt
    )
    await calendarApi?.select(
      event?._def.extendedProps?.dropoffAt ||
        event?._def.extendedProps?.pickupAt
    )

    if (home) {
      if (pathname.includes('Suppliers')) {
        navigate(`/Suppliers/Schedule/${id}`)
      } else if (pathname.includes('Customers')) {
        navigate(`/Customers/Schedule/${id}`)
      }
    }
  }

  const handleClass = ({ event }) => {
    const { extendedProps, defId } = event?._def
    if (extendedProps?.scheduleNotConfirmed) {
      return ['event-tbd', `event-${defId}`]
    } else {
      return ['event-finalized', `event-${defId}`]
    }
  }

  const renderEventContent = eventInfo => {
    const { productDescription, scheduleNotConfirmed } =
      eventInfo?.event?.extendedProps

    return (
      <Box sx={{ py: 0.5, px: 0.5, cursor: 'pointer' }}>
        <Tooltip title={eventInfo.event.title}>
          <Typography
            fontWeight={600}
            sx={{
              fontSize: { xs: 16, md: 14 },
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              color: scheduleNotConfirmed ? 'background.paper' : 'text.primary'
            }}>
            {eventInfo.event.title}
          </Typography>
        </Tooltip>
        {!home || !desktop ? (
          <Tooltip title={productDescription}>
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                color: scheduleNotConfirmed
                  ? 'background.paper'
                  : 'text.primary',
                textOverflow: 'ellipsis',
                fontSize: { xs: 14, md: 12 }
              }}>
              {productDescription}
            </Typography>
          </Tooltip>
        ) : null}
      </Box>
    )
  }

  return (
    <StyledBox position="relative" mb={!home && 12}>
      <LoadingWrapper visible={loading}>
        <FullCalendar
          ref={calendarRef}
          plugins={[listPlugin, dayGridPlugin]}
          headerToolbar={{
            left: 'prev next today',
            center: '',
            right: 'title'
          }}
          height="auto"
          initialView="dayGridMonth"
          selectable={true}
          dayMaxEvents={3}
          events={filteredEvents}
          eventSourceFailure={error => {
            const errorMessage = handleAxiosError(error)
            enqueueSnackbar(errorMessage, {
              preventDuplicate: true,
              variant: 'error',
              sx: snackBarFailure
            })
          }}
          eventContent={renderEventContent}
          eventClassNames={handleClass}
          eventClick={handleEventClick}
          initialDate={
            selectedEvent?.range?.end &&
            moment(selectedEvent?.range?.end).format('YYYY-MM-DD')
          }
        />
        <Grid columnSpacing={1} container sx={{ my: 1, width: '100%' }}>
          <Grid item>
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: 'primary.light',
                border: 1,
                borderRadius: 1,
                fontWeight: 600
              }}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 600 }}>Finalized</Typography>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: 'warning.main',
                border: 1,
                borderRadius: 1,
                fontWeight: 600
              }}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 600 }}>TBD</Typography>
          </Grid>
        </Grid>
      </LoadingWrapper>
    </StyledBox>
  )
}

Schedule.propTypes = {
  //createRef
  calendarref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  home: PropTypes.bool,
  handleReceivedData: PropTypes.func
}

export default Schedule
