import React from 'react'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useLocation, useParams } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Container,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Menu,
  ListItemText
} from '@mui/material'
import NotificationIcon from './NotificationIcon'
import ResponsiveDrawer from './Nav'
import { PropTypes } from 'prop-types'
import Icons from '@thesparklaboratory/react-myicons'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import Link from './Link'
import { useMsal } from '@azure/msal-react'
import Spacer from './Spacer'
import { handleAxiosError } from '../utils'
import { snackBarFailure } from '../theme'
import { logError } from '../services/sentry'
import { useSnackbar } from 'notistack'
import { useResetRecoilState } from 'recoil'
import { eventNotFoundAtom, selectedEventAtom } from '../recoil/schedule/atom'
import {
  clientDataAtom,
  contractDataGridAtom,
  contractLimitAtom,
  contractFilterModelAtom,
  contractSortModelAtom,
  invoiceDataGridAtom,
  invoiceLimitAtom,
  invoiceFilterModelAtom,
  invoiceSortModelAtom,
  locationFilterAtom,
  locationListAtom,
  scheduleAutocompleteFilterAtom,
  scheduleAutocompleteOptionsAtom,
  scheduleEventsAtom,
  scheduleFilterAtom
} from '../recoil/data/atom'
const UserCircleIcon = Icons.interfaceEssential.userCircle2

const drawerWidth = 220
const appBarHeight = 70

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  container: {
    background: theme.palette.background.default,
    marginTop: `calc(${theme.spacing(4)} + ${appBarHeight}px - ${
      theme.mixins.toolbar.minHeight
    }px - ${theme.spacing(3)}px)`,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: theme.spacing(4),
    height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px - ${
      theme.mixins.toolbar.minHeight
    }px)`
  },
  img: {
    width: 165,
    paddingLeft: 19
  },
  bar: {
    background: theme.palette.background.paper
  },
  title: {
    textTransform: 'capitalize',
    marginLeft: 14
  },
  icons: {
    color: '#CFD2D4',
    width: 24,
    marginRight: 19
  },
  list: {
    padding: 20,
    width: '100%',
    textAlign: 'left'
  },
  profile: {
    marginLeft: 19
  },
  listItem: {
    padding: 19,
    borderRadius: 4,
    '&&:hover': {
      background: theme.palette.primary.main
    }
  },
  drawer: {
    [theme.breakpoints.up('mdsm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('mdsm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },

    height: appBarHeight,
    boxShadow: 'inset 0px -1px 0px #E2E2EA',
    background: theme.palette.background.paper
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('mdsm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: theme.palette.secondary.main,
    width: drawerWidth
  },
  listItemText: {
    color: '#fff'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    }
  },
  select: {
    display: 'flex',
    '& .MuiSelect-select': {
      display: 'flex'
    }
  },
  selectLogo: {
    marginRight: theme.spacing(1.5),
    maxHeight: 20,
    margin: theme.spacing('auto', 1.5, 'auto', 0)
  },
  selectName: {
    '& span': {
      fontWeight: 600,
      textAlign: 'left',
      fontSize: 20,
      color: '#000'
    }
  },
  userIcon: {
    padding: 6,
    marginLeft: theme.spacing(2)
  },
  userSvg: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main
  }
}))

const PageContainer = ({ page, pageTitle }) => {
  const classes = useStyles()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('mdsm'))
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const resetSelectedEvent = useResetRecoilState(selectedEventAtom)
  const resetClientData = useResetRecoilState(clientDataAtom)
  const resetScheduleEvents = useResetRecoilState(scheduleEventsAtom)
  const resetScheduleFilter = useResetRecoilState(scheduleFilterAtom)
  const resetContractDataGrid = useResetRecoilState(contractDataGridAtom)
  const resetContractLimit = useResetRecoilState(contractLimitAtom)
  const resetContractFilterModel = useResetRecoilState(contractFilterModelAtom)
  const resetContractSortModel = useResetRecoilState(contractSortModelAtom)
  const resetEventNotFoundAtom = useResetRecoilState(eventNotFoundAtom)
  const resetInvoiceLimit = useResetRecoilState(invoiceLimitAtom)
  const resetInvoiceFilterModel = useResetRecoilState(invoiceFilterModelAtom)
  const resetInvoiceSortModel = useResetRecoilState(invoiceSortModelAtom)
  const resetInvoiceDataGrid = useResetRecoilState(invoiceDataGridAtom)
  const resetLocationFilter = useResetRecoilState(locationFilterAtom)
  const resetLocationList = useResetRecoilState(locationListAtom)
  const resetAutocompleteOptions = useResetRecoilState(
    scheduleAutocompleteOptionsAtom
  )
  const resetAutocompleteFilter = useResetRecoilState(
    scheduleAutocompleteFilterAtom
  )

  const handleLogout = instance => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: '/'
      })
      .catch(error => {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
      })
  }

  const { instance } = useMsal()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    if (pathname.includes('Client')) {
      resetSelectedEvent()
      resetClientData()
      resetScheduleEvents()
      resetScheduleFilter()
      resetContractDataGrid()
      resetContractLimit()
      resetContractFilterModel()
      resetContractSortModel()
      resetEventNotFoundAtom()
      resetInvoiceDataGrid()
      resetInvoiceLimit()
      resetInvoiceFilterModel()
      resetInvoiceSortModel()
      resetLocationFilter()
      resetLocationList()
      resetAutocompleteOptions()
      resetAutocompleteFilter()
    }
  }, [pathname])

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar color="secondary">
          <IconButton
            color="secondary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large">
            <MenuIcon />
          </IconButton>
          {desktop && (
            <Typography
              className={classes.title}
              color="secondary"
              variant="h5">
              {pageTitle}
            </Typography>
          )}
          <Grid justifyContent="flex-end" container>
            {pathname.includes('Suppliers') &&
              !pathname.includes('Notifications') && <NotificationIcon />}
            {pathname.includes('Customers') &&
              !pathname.includes('Notifications') && <NotificationIcon />}
            <IconButton
              className={classes.userIcon}
              onClick={handleClick}
              size="large">
              <UserCircleIcon className={classes.userSvg} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  p: 2,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <Link
                underline="hover"
                to={
                  pathname.includes('Suppliers')
                    ? `/Suppliers/Account/${id}`
                    : pathname.includes('Customers')
                    ? `/Customers/Account/${id}`
                    : `/Account`
                }>
                <MenuItem>
                  <ListItemIcon>
                    <SettingsIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </MenuItem>
              </Link>
              <Spacer m={2} />
              <Link underline="hover" onClick={() => handleLogout(instance)}>
                <MenuItem>
                  <ListItemIcon>
                    <LogoutIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Sign out</ListItemText>
                </MenuItem>
              </Link>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      <ResponsiveDrawer
        pageTitle={pageTitle}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container className={classes.container} maxWidth="xl">
          {page}
        </Container>
      </main>
    </Grid>
  )
}

PageContainer.propTypes = {
  page: PropTypes.object.isRequired,
  pageTitle: PropTypes.string
}

export default PageContainer
