import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles(() => ({
  loaderContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .6)',
    zIndex: 999
  },
  linearLoader: {
    width: '100%'
  }
}))

export const LoadingWrapper = ({ visible, children }) => {
  const classes = useStyles()

  return (
    <>
      {children}
      {visible && (
        <Box
          className={classes.loaderContent}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <CircularProgress color="primary" size={50} thickness={4} />
        </Box>
      )}
    </>
  )
}

LoadingWrapper.propTypes = {
  visible: PropTypes.bool
}
