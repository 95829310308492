import {
  Box,
  Modal,
  Typography,
  TextField,
  FormControl,
  Grid
} from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Controller, useForm } from 'react-hook-form'
import Button from './Button'
import { useSnackbar } from 'notistack'
import { addUser } from '../services'
import { snackBarFailure, snackBarSuccess } from '../theme'
import { logError } from '../services/sentry'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRecoilValue } from 'recoil'
import { companyListAtom } from '../recoil/company/atom'
import Autocomplete from './AutoComplete'
import { PropTypes } from 'prop-types'
import { userAtom } from '../recoil/user'
import ModalCloseButton from './ModalCloseButton'
import { handleAxiosError } from '../utils'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  form: {
    width: '100%',
    textAlign: 'center',
    margin: theme.spacing(3, 0)
  },
  uppercase: {
    textTransform: 'uppercase'
  }
}))

export const NewUserModal = ({ triggerRefresh = () => null }) => {
  const classes = useStyles()
  const companyList = useRecoilValue(companyListAtom)
  const user = useRecoilValue(userAtom)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const { enqueueSnackbar } = useSnackbar()

  const schema = yup.object().shape({
    displayName: yup.string().required('Please enter a display name'),
    email: yup.string().matches(/.+@.+\..+/, 'Email is invalid')
  })

  const defaultValues = {
    displayName: '',
    email: '',
    clients: []
  }

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({ resolver: yupResolver(schema), defaultValues })

  const onSubmit = async data => {
    try {
      setLoading(true)
      await addUser(data)
      setLoading(false)
      handleClose()
      enqueueSnackbar('User Successfully Added', {
        variant: 'success',
        sx: snackBarSuccess
      })
      triggerRefresh()
    } catch (error) {
      setLoading(false)
      const errorMessage = handleAxiosError(error)
      logError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    }
  }

  return (
    <>
      {user?.isAdmin && (
        <Button
          onClick={handleOpen}
          className={classes.uppercase}
          variant="outlined">
          {' '}
          Add New User
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="new-user-modal-title"
        aria-describedby="new-user-modal-description">
        <Box className={classes.paper}>
          <Box
            alignItems="center"
            mb={2}
            display="flex"
            justifyContent="space-between">
            <Typography color="secondary" variant="h5">
              Add a New User
            </Typography>
            <ModalCloseButton handleClose={handleClose} />
          </Box>
          <FormControl className={classes.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid alignItems="center" container rowSpacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="displayName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors?.displayName)}
                        fullWidth
                        helperText={errors?.displayName?.message}
                        label="Display Name"
                        margin="normal"
                        name="displayName"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors?.email)}
                        fullWidth
                        helperText={errors?.email?.message}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    margin="normal"
                    fullWidth
                    label="Client"
                    errors={errors}
                    options={companyList || []}
                    name="clients"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Button loading={loading} type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormControl>
        </Box>
      </Modal>
    </>
  )
}

NewUserModal.propTypes = {
  triggerRefresh: PropTypes.func
}

export default NewUserModal
