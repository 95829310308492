import { Box, Modal, Typography } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from './Button'
import Spacer from './Spacer'
import ModalCloseButton from './ModalCloseButton'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  }
}))

export const ResendInvitationModal = ({
  handleClick,
  loading,
  displayName,
  email,
  externalUserStateChangeDateTime
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <>
      <Button color="primary" onClick={handleOpen} variant="text">
        {' '}
        Resend Invitation
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description">
        <Box className={classes.paper}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            justifyContent="space-between">
            <Typography color="secondary" variant="h5">
              Resend Invitation
            </Typography>
            <ModalCloseButton handleClose={handleClose} />
          </Box>
          <Typography fontSize={16}>
            {' '}
            Are you sure you want to resend this invitation to{' '}
            {displayName || email}?
            {externalUserStateChangeDateTime &&
              ` The last invitation was sent ${externalUserStateChangeDateTime}.
            `}
          </Typography>
          <Spacer m={3} />
          <Box textAlign="center" width="100%">
            <Button
              loading={loading}
              onClick={() => {
                handleClick()
                handleClose()
              }}>
              Resend Invitation
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

ResendInvitationModal.propTypes = {
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
  displayName: PropTypes.string,
  email: PropTypes.string,
  externalUserStateChangeDateTime: PropTypes.string
}

export default ResendInvitationModal
