import companyImg from '../assets/placeholder-com.jpg'

export const companyData = {
  name: 'WWS Trading',
  address: `4032 Shoreline Dr, #2 <br/>
    Spring Park, MN 55384`,
  phone: ['1-888-645-6328', '1-952-548-9300'],
  afterHoursPhone: ['1-651-442-6676', '1-763-412-2074'],
  email: 'inquiry@wwstrading.com',
  img: companyImg
}

export const INITIAL_EVENTS = [
  {
    id: 'a1',
    title: '810000-1',
    arrival: '2pm-4pm',
    loadNumber: 14,
    number: 'AVFAT',
    productDescription: 'Edible Lard for Edible Feed',
    documentType: 'Blanket Order',
    dropOffDate: 'Blanket Order',
    dropoffLocation: 'Dodge City',
    unitPrice: '.434',
    quantity: 12,
    unitOfMeasure: 'pounds',
    dropoffAt: 'FIFO',
    customerPurchaseOrderNumber: '9534',
    // This value can be anything that can parse into a Date, including an ISO8601 date string like "2014-02-01".
    start: '2022-07-01',
    finalized: true
  },
  {
    id: 'a2',
    title: '810000-2',
    arrival: '2pm-4pm',
    loadNumber: 14,
    number: 'AVFAT',
    productDescription: 'Some sort of Feed',
    documentType: 'Blanket Order',
    dropOffDate: 'Blanket Order',
    dropoffLocation: 'Dodge City',
    unitPrice: '.434',
    quantity: 12,
    unitOfMeasure: 'pounds',
    dropoffAt: 'FIFO',
    customerPurchaseOrderNumber: '9534',
    // This value can be anything that can parse into a Date, including an ISO8601 date string like "2014-02-01".
    start: '2022-07-01',
    finalized: true
  },
  {
    id: 'a3',
    title: '810000-3',
    arrival: '2pm-4pm',
    loadNumber: 14,
    number: 'AVFAT',
    productDescription: 'Proteins',
    documentType: 'Blanket Order',
    dropOffDate: 'Blanket Order',
    dropoffLocation: 'Dodge City',
    unitPrice: '.434',
    quantity: 12,
    unitOfMeasure: 'pounds',
    dropoffAt: 'FIFO',
    customerPurchaseOrderNumber: '9534',
    // This value can be anything that can parse into a Date, including an ISO8601 date string like "2014-02-01".
    start: '2022-07-01',
    finalized: true
  },
  {
    id: 'a4',
    title: '810000-4',
    arrival: '2pm-4pm',
    loadNumber: 14,
    number: 'AVFAT',
    productDescription: 'Edible Lard for Edible Feed',
    documentType: 'Blanket Order',
    dropOffDate: 'Blanket Order',
    dropoffLocation: 'Dodge City',
    unitPrice: '.434',
    quantity: 12,
    unitOfMeasure: 'pounds',
    dropoffAt: 'FIFO',
    customerPurchaseOrderNumber: '9534',
    // This value can be anything that can parse into a Date, including an ISO8601 date string like "2014-02-01".
    start: '2022-07-01',
    finalized: true
  },
  {
    id: 'c',
    title: '747436-25',
    arrival: '2pm-4pm',
    loadNumber: 12,
    number: 'AVFAT',
    productDescription: 'Hog Fat - Bacon Fat for Feed use',
    documentType: 'Blanket Order',
    dropOffDate: 'Blanket Order',
    dropoffLocation: 'Dodge City',
    unitPrice: '.434',
    quantity: 12,
    unitOfMeasure: 'pounds',
    dropoffAt: 'FIFO',
    customerPurchaseOrderNumber: '9534',
    start: '2022-04-01',
    finalized: true
  },
  {
    id: 'd',
    title: '747436-28',
    arrival: '2pm-4pm',
    loadNumber: 12,
    number: 'AVFAT',
    productDescription: 'Some sort of Feed...',
    documentType: 'Blanket Order',
    dropOffDate: 'Blanket Order',
    dropoffLocation: 'Dodge City',
    unitPrice: '.434',
    quantity: 12,
    unitOfMeasure: 'pounds',
    dropoffAt: 'FIFO',
    customerPurchaseOrderNumber: '9534',
    start: '2022-05-04',
    finalized: false
    // if pending send through different background color
    // backgroundColor: '#EE9A26'
  }
]
