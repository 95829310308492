import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import theme, { snackBarFailure } from '../theme'
import { useParams, useSearchParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import CheckIcon from '@mui/icons-material/Check'
import Button from './Button'
import Icons from '@thesparklaboratory/react-myicons'
import { dateOnlyFormatter } from '../utils/gridFunctions'
import { getSecureDocumentUrl } from '../services'
import { handleAxiosError } from '../utils'
import { logError } from '../services/sentry'
import { ContractsFilterToolbar } from './CustomToolbar'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  clientDataAtom,
  contractDataGridAtom,
  contractFilterModelAtom,
  contractLimitAtom,
  contractSortModelAtom
} from '../recoil/data/atom'
import { useDidUpdateEffect } from '../hooks/useDidUpdateEffect'
import fastDeepEqual from 'fast-deep-equal'

const { documentContent15: DocumentIcon } = Icons.contentEdit

const useStyles = makeStyles(theme => ({
  viewPDF: {
    '& svg': {
      width: 24
    }
  },
  spacer: {
    height: theme.spacing(1.5)
  },
  dataRow: {
    background: theme.palette.background.paper
  },
  dropDownRow: {
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
    '& td': {
      color: '#0F1222'
    },
    '& tbody': {
      '& tr:nth-child(odd)': {
        background: theme.palette.background.default
      }
    }
  },
  tableHeading: {
    '& th': {
      color: '#535875',
      opacity: 0.84
    }
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  documentNumber: {
    color: '#0f1222'
  },
  error: {
    color: '#FC5A5A'
  },
  noStyleAnchor: {
    textDecoration: 'none'
  },
  overdue: {
    fontSize: 16,
    fontWeight: 600
  }
}))

const ContractDataTable = props => {
  const { service, columns, type, subColumns, ...dataGridProps } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const desktop = useMediaQuery(theme.breakpoints.up('xl'))
  const [limit, setLimit] = useRecoilState(contractLimitAtom)
  const [filterModel, setFilterModel] = useRecoilState(contractFilterModelAtom)
  const [sortModel, setSortModel] = useRecoilState(contractSortModelAtom)
  const [searchParams] = useSearchParams()
  const [colType, setColType] = React.useState([])
  const clientData = useRecoilValue(clientDataAtom)
  const dataReset = () => {
    setData({
      docs: [],
      pages: {},
      loading: true,
      page: 0,
      totalDocs: 0
    })
  }
  React.useEffect(() => {
    const documentNumber = searchParams.get('documentNumber')
    if (documentNumber) {
      const newModel = {
        items: [],
        quickFilterValues: [documentNumber],
        quickFilterLogicOperator: 'and'
      }
      // Detect substantive  change to avoid infinite loop
      if (!fastDeepEqual(newModel, filterModel)) {
        setFilterModel(newModel)
      }
    }
  }, [searchParams])

  React.useEffect(() => {
    if (data.pages[data.page]) {
      setData(prev => ({ ...prev, docs: data.pages[data.page] }))
      return
    }
    setData({
      docs: [],
      pages: {},
      loading: true,
      page: 0,
      filter: filterModel,
      sort: sortModel,
      totalDocs: 0
    })
  }, [])

  React.useEffect(() => {
    if (desktop) {
      setColType([
        { flex: 1 },
        { flex: 0.75 },
        { flex: 2 },
        { flex: 1 },
        { flex: 1 },
        { flex: 1 }
      ])
    } else {
      setColType([
        { minWidth: 200 },
        { minWidth: 200 },
        { minWidth: 300 },
        { minWidth: 200 },
        { minWidth: 200 },
        { minWidth: 200 }
      ])
    }
  }, [desktop])

  const definedCols = [
    {
      ...columns[0],
      ...colType[0]
    },
    {
      ...columns[1],
      ...colType[1],
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      type: 'number'
    },
    {
      ...columns[2],
      ...colType[2]
    },
    {
      type: 'singleSelect',
      valueOptions:
        clientData?.locations && clientData?.locations?.map(x => x.code),
      ...columns[3],
      ...colType[3]
    },
    {
      ...columns[4],
      ...colType[4],
      type: 'date',
      valueFormatter: dateOnlyFormatter,
      flex: 1
    },
    {
      ...columns[5],
      ...colType[5],
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (params.row.documentUrl) {
          return (
            <Box className={classes.viewPDF}>
              <Button
                onClick={() => {
                  let setWindow = window.open('', '_blank')
                  getSecureDocumentUrl(params.row.documentUrl).then(
                    secureUrl => (setWindow.location.href = secureUrl)
                  )
                }}
                startIcon={<DocumentIcon />}
                variant="outlined">
                {' '}
                View PDF
              </Button>
            </Box>
          )
        } else {
          return (
            <Typography sx={{ opacity: 0.5 }} fontStyle="italic">
              PDF not available
            </Typography>
          )
        }
      }
    }
  ]

  const definedSubColumns = [
    {
      ...subColumns[0],
      flex: 2
    },
    {
      ...subColumns[1],
      flex: 0.75,
      type: 'number',
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...subColumns[2],
      flex: 0.75,
      type: 'number',
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...subColumns[3],
      flex: 0.75,
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...subColumns[4],
      type: 'number',
      flex: 0.75,
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...subColumns[5],
      flex: 1
    },
    {
      ...subColumns[6],
      flex: 1
    },
    {
      ...subColumns[7],
      flex: 0.75,
      type: 'date',
      valueFormatter: dateOnlyFormatter
    },
    {
      ...subColumns[8],
      flex: 0.75,
      disableColumnMenu: true,
      sortable: false,
      filterable: false
    },
    {
      ...subColumns[9],
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return (
          <>
            {row.scheduleNotConfirmed && (
              <Box width="100%" display="flex" justifyContent="center">
                <CheckIcon color="error" />
              </Box>
            )}
          </>
        )
      }
    }
  ]

  const [data, setData] = useRecoilState(contractDataGridAtom)

  React.useEffect(() => {
    // utilize caching to speed up pagination support
    if (data.pages[data.page]) {
      setData(prev => ({ ...prev, docs: data.pages[data.page] }))
      return
    }

    let active = true
    ;(async () => {
      setData(prev => ({ ...prev, loading: true }))
      const results = await service(
        id,
        data.page + 1,
        limit,
        sortModel,
        filterModel
      )

      if (!active) {
        return
      }

      const pages = Object.assign({}, data.pages)
      pages[data.page] = results.docs
      setData(prev => ({
        ...prev,
        loading: false,
        docs: results.docs,
        totalDocs: results.totalDocs,
        pages
      }))
    })().catch(error => {
      const errorMessage = handleAxiosError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
      logError(error)
    })
    return () => {
      active = false
    }
  }, [data.page, enqueueSnackbar, data.pages])

  // Reset the data when limit, filterModel or sortModel changes
  useDidUpdateEffect(() => {
    dataReset()
  }, [limit, filterModel, sortModel])

  const getDetailPanelContent = React.useCallback(({ row }) => {
    return (
      <DataGridPro
        disableMultipleSelection={true}
        autoHeight
        sx={{
          flex: 1,
          '& .MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
            width: 'auto'
          },
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
            {
              opacity: 0.5
            }
        }}
        rowThreshold={0}
        getRowId={row =>
          type === 'Customer'
            ? row?.customerContractNumber + row?.customerContractLineNumber
            : row?.supplierContractNumber + row?.supplierContractLineNumber
        }
        rows={
          type === 'Customer'
            ? row?.customerContractLines
            : row?.supplierContractLines
        }
        columns={definedSubColumns}
        hideFooter
      />
    )
  }, [])

  const getDetailPanelHeight = React.useCallback(() => 'auto', [])

  return (
    <DataGridPro
      disableRowGrouping={true}
      disableColumnMenu
      initialState={{
        filter: { filterModel: filterModel },
        sorting: { sortModel: sortModel }
      }}
      sortModel={sortModel}
      filterModel={filterModel}
      sx={{
        '& .MuiDataGrid-iconButtonContainer': {
          visibility: 'visible',
          width: 'auto'
        },
        '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
          {
            opacity: 0.5
          }
      }}
      rowThreshold={0}
      autoHeight={true}
      {...dataGridProps}
      columns={definedCols}
      filterMode="server"
      components={{
        Toolbar: ContractsFilterToolbar
      }}
      getRowId={row => row?.documentNumber}
      loading={data.loading}
      page={data.page}
      pageSize={limit}
      pagination
      getDetailPanelContent={getDetailPanelContent}
      paginationMode="server"
      rowCount={data.totalDocs}
      disableMultipleSelection={true}
      rows={data.docs}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      sortingMode="server"
      getDetailPanelHeight={getDetailPanelHeight}
      onFilterModelChange={model => {
        if (model.quickFilterValues.length) {
          searchParams.set('documentNumber', model.quickFilterValues[0])
        } else {
          searchParams.delete('documentNumber')
        }
        setFilterModel(model)
      }}
      onPageChange={page => setData(prev => ({ ...prev, page }))}
      onPageSizeChange={n => setLimit(n)}
      onSortModelChange={model => setSortModel(model)}
    />
  )
}

ContractDataTable.propTypes = {
  type: PropTypes.oneOf(['Customer', 'Supplier']),
  service: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  subColumns: PropTypes.array.isRequired
}

export default ContractDataTable
