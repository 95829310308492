import { atom } from 'recoil'

export const selectedEventAtom = atom({
  key: 'selectedEvent',
  default: {}
})

export const eventNotFoundAtom = atom({
  key: 'eventNotFound',
  default: false
})

export const preSearchEventAtom = atom({
  key: 'preSearchEvent',
  default: null
})
