import { Grid } from '@mui/material'
import React from 'react'
import {
  getCustomer,
  getCustomerContractGridData,
  getSupplier,
  getSupplierContractGridData
} from '../services'
import ContractDataTable from '../components/ContractDataTable'
import {
  customerContractColumns,
  customerSubColumns,
  supplierContractColumns,
  supplierSubColumns
} from '../data/contractData'
import MobileTitle from '../components/MobileTitle'
import { useLocation, useParams } from 'react-router-dom'
import { snackBarFailure } from '../theme'
import TraderContactBar from '../components/TraderContact'
import { handleAxiosError } from '../utils'
import { useSnackbar } from 'notistack'
import { logError } from '../services/sentry'
import { useRecoilState } from 'recoil'
import { clientDataAtom } from '../recoil/data'

const ContractsPage = () => {
  const { pathname } = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [clientData, setClientData] = useRecoilState(clientDataAtom)
  const [loading, setLoading] = React.useState(false)
  const { id } = useParams()

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        if (pathname.includes('Suppliers')) {
          const response = await getSupplier(id)
          await setClientData(response)
        } else if (pathname.includes('Customers')) {
          const response = await getCustomer(id)
          await setClientData(response)
        }
        setLoading(false)
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
        setLoading(false)
      }
    }
    if (!clientData) {
      getData()
    }
  }, [])

  return (
    <Grid spacing={4} container>
      <MobileTitle title="Contracts" />
      <Grid item xl={9} xs={12}>
        <TraderContactBar loading={loading} {...clientData?.trader} />
      </Grid>
      <Grid mb={12} item xs={12}>
        <ContractDataTable
          type={pathname.includes('Customers') ? 'Customer' : 'Supplier'}
          service={
            pathname.includes('Customers')
              ? getCustomerContractGridData
              : getSupplierContractGridData
          }
          columns={
            pathname.includes('Customers')
              ? customerContractColumns
              : supplierContractColumns
          }
          subColumns={
            pathname.includes('Customers')
              ? customerSubColumns
              : supplierSubColumns
          }
        />
      </Grid>
    </Grid>
  )
}

export default ContractsPage
