import React from 'react'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Icons from '@thesparklaboratory/react-myicons'
import logo from '../assets/wws-trading.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  useMediaQuery,
  Divider,
  Box
} from '@mui/material'
import { userAtom } from '../recoil/user'
import { useRecoilValue } from 'recoil'
import { checkSingleClientUser } from '../utils'
import { PropTypes } from 'prop-types'

const drawerWidth = 220
const appBarHeight = 70

const { home: HomeIcon, calendarScheduleCheckmark: ScheduleIcon } =
  Icons.interfaceEssential
const { documentContent15: ContractsIcon } = Icons.contentEdit
const { invoiceAccounting1: InvoiceIcon } = Icons.paymentsFinance
const { usersCircle: UsersCircleIcon, briefcase: BriefcaseIcon } =
  Icons.interfaceEssential

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  container: {
    background: theme.palette.background.default,
    marginTop: `calc(${theme.spacing(4)} + ${appBarHeight}px - ${
      theme.mixins.toolbar.minHeight
    }px - ${theme.spacing(3)}px)`,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: theme.spacing(4),
    height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px - ${
      theme.mixins.toolbar.minHeight
    }px)`
  },
  img: {
    width: 165,
    paddingLeft: 19
  },
  bar: {
    background: theme.palette.background.paper
  },
  title: {
    textTransform: 'capitalize',
    marginLeft: 14
  },
  icons: {
    color: '#CFD2D4',
    width: 24,
    marginRight: 19
  },
  list: {
    padding: 20,
    width: '100%',
    textAlign: 'left'
  },
  profile: {
    marginLeft: 19
  },
  listItem: {
    padding: 19,
    borderRadius: 4,
    '&&:hover': {
      background: 'rgba(0, 0, 0, 0.4)'
    }
  },
  drawer: {
    [theme.breakpoints.up('mdsm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('mdsm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },

    height: appBarHeight,
    boxShadow: 'inset 0px -1px 0px #E2E2EA',
    background: theme.palette.background.paper
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('mdsm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: theme.palette.secondary.main,
    width: drawerWidth
  },
  listItemText: {
    color: '#fff'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    }
  },
  select: {
    display: 'flex',
    '& .MuiSelect-select': {
      display: 'flex'
    }
  },
  selectLogo: {
    marginRight: theme.spacing(1.5),
    maxHeight: 20,
    margin: theme.spacing('auto', 1.5, 'auto', 0)
  },
  selectName: {
    '& span': {
      fontWeight: 600,
      textAlign: 'left',
      fontSize: 20,
      color: '#000'
    }
  }
}))

const ResponsiveDrawer = ({ pageTitle, handleDrawerToggle, mobileOpen }) => {
  const navigate = useNavigate()
  const user = useRecoilValue(userAtom)
  const { pathname } = useLocation()
  const { id } = useParams()

  const classes = useStyles()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('mdsm'))

  const handleConditionalNav = route => {
    if (pathname.includes('Suppliers')) {
      navigate(`/Suppliers${route}/${id}`)
    } else if (pathname.includes('Customers')) {
      navigate(`/Customers${route}/${id}`)
    }
    // navigate(route)
    if (!desktop) {
      handleDrawerToggle()
    }
  }

  const handleNav = route => {
    navigate(route)
    if (!desktop) {
      handleDrawerToggle()
    }
  }

  const drawer = (
    <Grid container>
      <Grid />
      <List className={classes.list}>
        <img alt="logo" className={classes.img} src={logo} />
        {user?.isAdmin ? (
          <Box>
            <ListItem
              onClick={() => handleNav('/Clients')}
              className={classes.listItem}
              style={{
                background:
                  pageTitle === 'Clients' && theme.palette.primary.main
              }}
              button
              key="Clients">
              <ListItemIcon>
                <BriefcaseIcon className={classes.icons} />{' '}
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Clients"
              />
            </ListItem>
            <ListItem
              onClick={() => handleNav('/Users')}
              className={classes.listItem}
              button
              style={{
                background: pageTitle === 'Users' && theme.palette.primary.main
              }}
              key="Users">
              <ListItemIcon>
                <UsersCircleIcon className={classes.icons} />{' '}
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary="Users" />
            </ListItem>
            <Divider
              flexItem={true}
              sx={{
                margin: '19px',
                borderColor: '#CFD2D480'
              }}
            />
          </Box>
        ) : !checkSingleClientUser(user) ? (
          <Box>
            <ListItem
              onClick={() => handleNav('/Clients')}
              className={classes.listItem}
              style={{
                background:
                  pathname.includes('/Clients') && theme.palette.primary.main
              }}
              button
              key="Clients">
              <ListItemIcon>
                <BriefcaseIcon className={classes.icons} />{' '}
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Clients"
              />
            </ListItem>
            <Divider
              flexItem={true}
              sx={{
                margin: '19px',
                borderColor: '#CFD2D480'
              }}
            />
          </Box>
        ) : null}
        {pathname.includes('Suppliers') || pathname.includes('Customers') ? (
          <Box>
            <ListItem
              onClick={() => handleConditionalNav('')}
              className={classes.listItem}
              style={{
                background: pageTitle === 'Home' && theme.palette.primary.main
              }}
              button
              key="Home">
              <ListItemIcon>
                <HomeIcon className={classes.icons} />{' '}
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary="Home" />
            </ListItem>
            <ListItem
              onClick={() => handleConditionalNav('/Schedule')}
              className={classes.listItem}
              button
              style={{
                background:
                  pageTitle === 'Schedule' && theme.palette.primary.main
              }}
              key="Schedule">
              <ListItemIcon>
                <ScheduleIcon className={classes.icons} />{' '}
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Schedule"
              />
            </ListItem>
            <ListItem
              onClick={() => handleConditionalNav('/Contracts')}
              className={classes.listItem}
              button
              style={{
                background:
                  pageTitle === 'Contracts' && theme.palette.primary.main
              }}
              key="Contracts">
              <ListItemIcon>
                <ContractsIcon className={classes.icons} />{' '}
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Contracts"
              />
            </ListItem>
            {pathname.includes('Customers') && (
              <ListItem
                style={{
                  background:
                    pageTitle === 'Invoices' && theme.palette.primary.main
                }}
                onClick={() => handleConditionalNav('/Invoices')}
                className={classes.listItem}
                button
                key="Invoices">
                <ListItemIcon>
                  <InvoiceIcon className={classes.icons} />{' '}
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary="Invoices"
                />
              </ListItem>
            )}
          </Box>
        ) : null}
      </List>
    </Grid>
  )

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        sx={{
          display: { mdsm: 'block', xl: 'none', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}>
        {drawer}
      </Drawer>
      <Drawer
        sx={{
          display: {
            sm: 'none',
            xs: 'none',
            mdsm: 'block',
            md: 'block',
            lg: 'block'
          }
        }}
        classes={{
          paper: classes.drawerPaper
        }}
        variant="permanent"
        open>
        {drawer}
      </Drawer>
    </nav>
  )
}

ResponsiveDrawer.propTypes = {
  pageTitle: PropTypes.string,
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired
}

export default ResponsiveDrawer
