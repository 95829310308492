import { Box } from '@mui/system'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { LoadingWrapper } from '../components/LoadingWrapper'
import { userAtom } from '../recoil/user'
import { checkSingleClientUser } from '../utils'
import NotificationPreferences from '../components/NotificationPreferences'

const AccountLandingPage = ({ plain }) => {
  const user = useRecoilValue(userAtom)

  const singleUser = checkSingleClientUser(user)

  // User is already signedIn

  React.useEffect(() => {
    if (user) {
      handleNavigation()
    }
  }, [user])

  const handleNavigation = () => {
    if (!singleUser) {
      return
    } else if (user?.customers?.length) {
      window.location.href = `${window.location.origin}/Customers/Account/${user.customers[0]?.customerNumber}`
    } else {
      window.location.href = `${window.location.origin}/Suppliers/Account/${user.suppliers[0]?.supplierNumber}`
    }
  }

  return (
    <>
      {singleUser ? (
        <LoadingWrapper visible={true}>
          <Box height={800} width="100%" />{' '}
        </LoadingWrapper>
      ) : (
        <NotificationPreferences />
      )}
    </>
  )
}

export default AccountLandingPage
