import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Link from './Link'
import Icons from '@thesparklaboratory/react-myicons'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { eventNotFoundAtom, selectedEventAtom } from '../recoil/schedule/atom'
import { ReactComponent as SearchSvg } from '../assets/search.svg'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

const { deliveryTruckClock: DeliveryTruckClockIcon } = Icons.delivery

const useStyles = makeStyles(theme => ({
  deliveryIcon: {
    width: 24,
    marginRight: theme.spacing(1.5)
  },
  emptyDeliveryIcon: {
    width: 48,
    color: theme.palette.primary.main
  },
  arrivalCard: {
    background: '#F8F8FA',
    padding: theme.spacing(1.5, 2, 1),
    marginTop: theme.spacing(1.5),
    borderRadius: 5
  },
  description: {
    color: '#535875',
    mixBlendMode: 'normal',
    opacity: 0.84
  },
  value: {
    color: '#535875'
  },
  link: {
    letterSpacing: 0.75,
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontFamily: 'Roboto'
  },
  bold: {
    color: '#0F1222'
  },
  content: {
    textAlign: 'left',
    '& > div': {
      marginBottom: theme.spacing(2.5)
    }
  },
  secondRowGrid: {
    marginLeft: theme.spacing(3.5)
  },
  title: {
    fontSize: 16,
    color: '#252631',
    fontWeight: 600
  },
  id: {
    fontSize: 26,
    color: '#0F1222',
    fontWeight: 600
  }
}))
export const CustomerLoadInformation = () => {
  const classes = useStyles()
  const selectedEvent = useRecoilValue(selectedEventAtom)
  const { extendedProps, title } = selectedEvent
  const notFound = useRecoilValue(eventNotFoundAtom)
  const { id } = useParams()
  const navigate = useNavigate()

  const handleClick = async documentNumber => {
    // ** this recoil didn't update in time without the await
    navigate(`/Customers/Contracts/${id}?documentNumber=${documentNumber}`)
  }

  return (
    <Card
      sx={{
        minHeight: 300
      }}>
      {notFound ? (
        <CardContent>
          <SearchSvg />
          <Typography gutterBottom sx={{ fontWeight: 'bold' }} variant="h4">
            Load not found.
          </Typography>
          <Typography sx={{ maxWidth: '44ch', fontSize: 18, lineHeight: 1.8 }}>
            Sorry, we can't find what you're looking for... Please try again or
            contact us for help
          </Typography>
        </CardContent>
      ) : extendedProps ? (
        <CardContent className={classes.content}>
          <Typography mt={1} mb={1} className={classes.title}>
            Load Information
          </Typography>
          {extendedProps?.scheduleNotConfirmed && (
            <Typography
              component="span"
              sx={{
                color: 'warning.main',
                fontWeight: 600
              }}>
              Pending
            </Typography>
          )}
          <Typography mb={1} className={classes.id}>
            {title}
          </Typography>
          <Link
            underline="hover"
            onClick={() => handleClick(extendedProps?.customerContractNumber)}
            className={classes.link}>
            View Contract
          </Link>

          <Grid className={classes.arrivalCard} container>
            <DeliveryTruckClockIcon className={classes.deliveryIcon} />
            <Grid item>
              <Typography className={classes.description}>
                Drop Off Time
              </Typography>
              <Typography className={classes.bold}>
                {extendedProps?.dropoffTime}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography className={classes.description}>
              Product Description
            </Typography>
            <Typography className={classes.value}>
              {extendedProps?.productDescription}
            </Typography>
          </Grid>
          {extendedProps?.dropoffAt && (
            <Grid>
              <Typography className={classes.description}>
                Drop Off Date
              </Typography>
              <Typography className={classes.value}>
                {moment(extendedProps?.dropoffAt).format('MM/DD/yyyy')}
              </Typography>
            </Grid>
          )}
          <Grid>
            <Typography className={classes.description}>
              Drop Off Location
            </Typography>
            <Typography className={classes.value}>
              {extendedProps?.dropoffLocation}
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.description}>Unit Price</Typography>
            <Typography className={classes.value}>
              {extendedProps?.unitPrice}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography className={classes.description}>Quantity</Typography>
              <Typography className={classes.value}>
                {extendedProps?.quantity}
              </Typography>
            </Grid>
            <Grid className={classes.secondRowGrid} item>
              <Typography className={classes.description}>
                Unit of Measure
              </Typography>
              <Typography className={classes.value}>
                {extendedProps?.unitOfMeasure}
              </Typography>
            </Grid>
          </Grid>
          {extendedProps?.adjustment && (
            <Grid container>
              <Grid mb={1} xs={12} item>
                <Typography fontWeight="bold" className={classes.description}>
                  Adjustment
                </Typography>
                <Typography className={classes.value}>
                  {extendedProps?.adjustment?.description}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.description}>
                  Unit Price
                </Typography>
                <Typography className={classes.value}>
                  {extendedProps?.adjustment?.unitPrice}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      ) : !selectedEvent ? (
        <CardContent>
          <DeliveryTruckClockIcon
            color="primary"
            className={classes.emptyDeliveryIcon}
          />
          <Typography gutterBottom variant="h5">
            No Loads Scheduled Today
          </Typography>
          <Typography>Select a load on the calendar to view details</Typography>
        </CardContent>
      ) : null}
    </Card>
  )
}
