import React from 'react'
import { logError } from '../services/sentry'
import { getUser } from '../services'
import { userAtom } from '../recoil/user'
import { LoadingWrapper } from '../components/LoadingWrapper'
import { useMsal } from '@azure/msal-react'
import { Box, Button, Typography } from '@mui/material'
import { checkSingleClientUser, handleAxiosError } from '../utils'
import { useSnackbar } from 'notistack'
import { snackBarFailure } from '../theme'
import { useSetRecoilState } from 'recoil'
import logo from '../assets/wws-trading-white.png'
import Spacer from '../components/Spacer'

const LandingPage = () => {
  const setUser = useSetRecoilState(userAtom)
  const [unassignedClient, setUnassignedClient] = React.useState(false)
  const { instance } = useMsal()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogout = instance => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: '/'
      })
      .catch(error => {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
      })
  }

  React.useEffect(() => {
    const afterLogin = async () => {
      try {
        const userResponse = await getUser(
          instance.getActiveAccount()?.username
        )
        await setUser(userResponse)
        if (
          !userResponse.customers.length &&
          !userResponse.suppliers.length &&
          !userResponse.isAdmin
        ) {
          setUnassignedClient(true)
          return
        }

        const singleClientUser = checkSingleClientUser(userResponse)

        if (!singleClientUser) {
          window.location.href = `${window.location.origin}/Clients`
        } else if (userResponse.customers.length) {
          window.location.href = `${window.location.origin}/Customers/${userResponse.customers[0]?.customerNumber}`
        } else {
          window.location.href = `${window.location.origin}/Suppliers/${userResponse.suppliers[0]?.supplierNumber}`
        }
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(errorMessage)
      }
    }
    afterLogin()
  }, [])

  return (
    <LoadingWrapper visible={!unassignedClient}>
      {unassignedClient ? (
        <Box
          sx={{ '& img': { width: '100%', mb: 2 } }}
          mt={16}
          maxWidth={370}
          mx="auto">
          <img src={logo} alt="logo" />
          <Typography mb={2} fontWeight="bold" variant="h4">
            You haven't been assigned to a client yet.
          </Typography>
          <Typography color="text.gray" fontSize={18}>
            Please contact your trader to be assigned to the proper client
          </Typography>
          <Spacer m={4} />
          <Button
            size="large"
            variant="text"
            onClick={() => handleLogout(instance)}>
            <Typography fontSize={18}>Sign Out</Typography>
          </Button>
        </Box>
      ) : (
        <Box height={800} width="100%" />
      )}
    </LoadingWrapper>
  )
}

export default LandingPage
