import {
  Box,
  ListItemIcon,
  Modal,
  Typography,
  TextField,
  FormControl
} from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useForm } from 'react-hook-form'
import SendIcon from '@mui/icons-material/Send'
import Button from './Button'
import { useSnackbar } from 'notistack'
import { snackBarFailure, snackBarSuccess } from '../theme'
import { logError } from '../services/sentry'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { postFeedback } from '../services'
import ModalCloseButton from './ModalCloseButton'
import { handleAxiosError } from '../utils'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  icon: {},
  button: {
    padding: 0
  },
  form: {
    width: '100%',
    textAlign: 'center',
    margin: theme.spacing(3, 0)
  },
  sidebarIcon: {
    marginRight: theme.spacing(2),
    height: 24,
    width: 54,
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export const FeedbackModal = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const { enqueueSnackbar } = useSnackbar()

  const schema = yup.object().shape({
    message: yup.string().required('Cannot be empty')
  })

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = async data => {
    try {
      setLoading(true)
      await postFeedback(data)
      enqueueSnackbar('Thank you for the feedback', {
        variant: 'success',
        sx: snackBarSuccess
      })
      setLoading(false)
      handleClose()
    } catch (e) {
      logError(e)
      const errorMessage = handleAxiosError(e)
      setLoading(false)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    }
  }

  return (
    <>
      <Button variant="text" onClick={handleOpen} className={classes.button}>
        <ListItemIcon className={classes.sidebarIcon}>
          <SendIcon color="primary" />
        </ListItemIcon>
        <Typography sx={{ fontWeight: 'bold' }}>Feedback</Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description">
        <Box className={classes.paper}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            justifyContent="space-between">
            <Typography color="secondary" variant="h5">
              Feedback
            </Typography>
            <ModalCloseButton handleClose={handleClose} />
          </Box>
          <Typography>
            {' '}
            Please provide any feedback you have on the WWS Trading App:
          </Typography>
          <FormControl className={classes.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={3}>
                <TextField
                  {...register('message')}
                  variant="outlined"
                  error={errors?.feedback}
                  helperText={errors?.feedback?.message}
                  fullWidth={true}
                  multiline
                  minRows={3}
                  maxRows={12}
                />
              </Box>
              <Button
                loading={loading}
                sx={{ margin: 'auto' }}
                type="submit"
                className={classes.submit}>
                Submit
              </Button>
            </form>
          </FormControl>
        </Box>
      </Modal>
    </>
  )
}

export default FeedbackModal
