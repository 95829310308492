import { Badge, Box, IconButton, Menu, MenuList } from '@mui/material'
import React from 'react'
import Icons from '@thesparklaboratory/react-myicons'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Link from './Link'
import { useLocation, useParams } from 'react-router-dom'
import { getCustomerNotifications, getSupplierNotifications } from '../services'
import { logError } from '../services/sentry'
import NotificationComponent from './NotificationComponent'
import { useSnackbar } from 'notistack'
import { handleAxiosError } from '../utils'
import { snackBarFailure } from '../theme'

const { bellNotification: BellNotificationIcon } = Icons.interfaceEssential

const useStyles = makeStyles(theme => ({
  badge: {
    '& svg': {
      width: 30,
      color: '#92929D'
    }
  },
  styledMenu: {
    '& svg': {
      width: 24
    }
  },
  icons: {
    color: theme.palette.primary.main
  },
  notificationButton: {
    padding: 6
  }
}))

const StyledMenu = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const NotificationIcon = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { id } = useParams()
  const [notifications, setNotifications] = React.useState([])
  const { enqueueSnackbar } = useSnackbar()

  const triggerRefresh = () => {
    // ** Timeout to give enough time for server to update to show that notification has been read
    setTimeout(() => {
      getData(true)
    }, 5000)
  }

  let timer

  const getData = async noTimer => {
    if (!document.hidden) {
      try {
        if (pathname.includes('Customers')) {
          const data = await getCustomerNotifications(id, 1, 5)
          setNotifications(data.docs)
        } else if (pathname.includes('Suppliers')) {
          const data = await getSupplierNotifications(id, 1, 5)
          setNotifications(data.docs)
        }
      } catch (error) {
        logError(error)
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
      }
      if (!noTimer) {
        timer = setTimeout(getData, 900000)
      }
    }
  }

  React.useEffect(() => {
    getData()
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    triggerRefresh()
  }

  return (
    <Badge
      className={classes.badge}
      invisible={notifications?.every(x => x.isAcknowledged === true)}
      overlap="circular"
      color="error"
      variant="dot">
      <IconButton
        className={classes.notificationButton}
        onClick={handleClick}
        size="large">
        <BellNotificationIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        className={classes.styledMenu}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuList dense>
          {notifications?.map((x, i) => (
            <NotificationComponent
              handleClose={handleClose}
              key={i}
              menu
              {...x}
            />
          ))}
        </MenuList>
        <Box p={2} mt={2.5} textAlign="center">
          <Link
            onClick={handleClose}
            to={
              pathname.includes('Suppliers')
                ? `/Suppliers/Notifications/${id}`
                : `/Customers/Notifications/${id}`
            }>
            All Notifications
          </Link>
        </Box>
      </StyledMenu>
    </Badge>
  )
}

export default NotificationIcon
