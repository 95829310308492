import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles';
import { Link as MaterialLink } from '@mui/material'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 14,
    fontWeight: 600
  }
}))

const Link = ({ children, color = 'primary', className, ...props }) => {
  const classes = useStyles()
  const element =
    'to' in props ? (
      <MaterialLink
        color={color}
        component={RouterLink}
        className={`${classes.root} ${className}`}
        {...props}>
        {children}
      </MaterialLink>
    ) : (
      <MaterialLink
        target="_blank"
        color={color}
        className={`${classes.root} ${className}`}
        {...props}>
        {children}
      </MaterialLink>
    )
  return element
}

export default Link