import React from 'react'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import { loginRequest } from '../authConfig'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { logError } from '../services/sentry'
import { useSnackbar } from 'notistack'
import { snackBarFailure } from '../theme'

const RequestInterceptor = ({ children }) => {
  const { instance } = useMsal()
  const { enqueueSnackbar } = useSnackbar()

  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  React.useEffect(() => {
    const refreshAccessToken = async () => {
      try {
        const token = await instance.acquireTokenSilent({
          ...loginRequest,
          account: instance.getActiveAccount()
        })
        return token.accessToken
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          return instance
            .acquireTokenRedirect({
              loginRequest,
              account: instance.getActiveAccount()
            })
            .then(resp => {
              return resp.accessToken
            })
            .catch(err => {
              logError(err)
              enqueueSnackbar(err, {
                variant: 'error',
                sx: snackBarFailure
              })
            })
        } else {
          logError(error)
        }
      }
    }

    axios.interceptors.request.use(async config => {
      // if (!account) {
      //   throw Error('No active account! Verify a user has been signed in.')
      // }

      const token = await refreshAccessToken()

      const bearer = `Bearer ${token}`
      config.headers.Authorization = bearer
      // config.headers['Access-Control-Allow-Origin'] = '*'

      return config
    })

    axios.interceptors.response.use(
      res => res,
      err => {
        throw new Error(err?.response?.data?.message)
      }
    )
  }, [instance])

  return <>{children}</>
}

export default RequestInterceptor
