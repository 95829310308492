export const usersColumn = [
  {
    field: 'firstName',
    headerName: 'First Name'
  },
  {
    field: 'lastName',
    headerName: 'Last Name'
  },
  {
    field: 'email',
    headerName: 'Email Address'
  },
  {
    field: 'clients',
    headerName: 'Clients'
  },
  {
    field: 'edit',
    headerName: ''
  }
]
