/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/Home'
import ContractsPage from './pages/Contracts'
import InvoicesPage from './pages/Invoices'
import SchedulePage from './pages/Schedule'
import AccountPage from './pages/Account'
import ClientsPage from './pages/Clients'
import { useSetRecoilState } from 'recoil'
import { getUser } from './services'
import { useSnackbar } from 'notistack'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react'

import { LicenseInfo } from '@mui/x-license-pro'
import NotFound from './pages/NotFound'
import SignInPage from './pages/SignIn'
import UsersPage from './pages/Users'
import RequestInterceptor from './components/RequestInterceptor'
import { EventType } from '@azure/msal-browser'
import { userAtom } from './recoil/user'
import { logError } from './services/sentry'
import PageContainer from './components/PageContainer'
import NotificationsPage from './pages/Notifications'
import LandingPage from './pages/Landing'
import { handleAxiosError } from './utils'
import AccountLandingPage from './pages/AccountLanding'
import ScrollToTop from './components/ScrollToTop'

LicenseInfo.setLicenseKey(
  'f0a836e3da2b090f355fc968fbd2884dT1JERVI6NDE5MDksRVhQSVJZPTE2ODE1ODExNzAwMDAsS0VZVkVSU0lPTj0x'
)

Sentry.init({
  dsn: 'https://162096fd05964f4a8be9751fa5c56c4e@sentry.sparklabs.us/36',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

function App() {
  const { enqueueSnackbar } = useSnackbar()
  const { instance } = useMsal()
  const setUser = useSetRecoilState(userAtom)
  const isAuthenticated = useIsAuthenticated()

  window.onSIApiReady = () => {
    if (!isAuthenticated) {
      setTimeout(() => {
        window.SI_API.hideTab()
        window.SI_API.hidePopup()
      }, 500)
    }
  }

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse = await getUser(
          instance.getActiveAccount()?.username
        )
        await setUser(userResponse)
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
        logError(error)
      }
    }

    instance.addEventCallback(message => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {
      }
    })

    if (instance.getActiveAccount()?.username) {
      fetchUser()
    }
  }, [])

  return (
    <div className="App">
      <RequestInterceptor>
        {' '}
        <Router>
          <ScrollToTop />
          <AuthenticatedTemplate>
            <Routes>
              <Route
                path="/Customers/:id"
                element={<PageContainer pageTitle="Home" page={<HomePage />} />}
              />
              <Route
                path="/Suppliers/:id"
                element={<PageContainer pageTitle="Home" page={<HomePage />} />}
              />
              <Route
                path="/Clients"
                element={
                  <PageContainer pageTitle="Clients" page={<ClientsPage />} />
                }
              />
              <Route
                path="/Users"
                element={
                  <PageContainer pageTitle="Users" page={<UsersPage />} />
                }
              />
              <Route
                path="/Customers/Schedule/:id"
                element={
                  <PageContainer pageTitle="Schedule" page={<SchedulePage />} />
                }
              />
              <Route
                path="/Suppliers/Schedule/:id"
                element={
                  <PageContainer pageTitle="Schedule" page={<SchedulePage />} />
                }
              />
              <Route
                path="/Suppliers/Contracts/:id"
                element={
                  <PageContainer
                    pageTitle="Contracts"
                    page={<ContractsPage />}
                  />
                }
              />
              <Route
                path="/Customers/Contracts/:id"
                element={
                  <PageContainer
                    pageTitle="Contracts"
                    page={<ContractsPage />}
                  />
                }
              />
              <Route
                path="Customers/Invoices/:id"
                element={
                  <PageContainer pageTitle="Invoices" page={<InvoicesPage />} />
                }
              />
              <Route
                path="Suppliers/Invoices/:id"
                element={
                  <PageContainer pageTitle="Invoices" page={<InvoicesPage />} />
                }
              />
              <Route
                path="Customers/Account/:id"
                pageTitle="Account"
                element={
                  <PageContainer pageTitle="Account" page={<AccountPage />} />
                }
              />
              <Route
                path="Suppliers/Account/:id"
                pageTitle="Account"
                element={
                  <PageContainer pageTitle="Account" page={<AccountPage />} />
                }
              />
              <Route
                path="Suppliers/Notifications/:id"
                pageTitle="Notifications"
                element={
                  <PageContainer
                    pageTitle="Notifications"
                    page={<NotificationsPage />}
                  />
                }
              />
              <Route
                path="Customers/Notifications/:id"
                pageTitle="Notifications"
                element={
                  <PageContainer
                    pageTitle="Notifications"
                    page={<NotificationsPage />}
                  />
                }
              />
              <Route
                path="/Account"
                element={
                  <PageContainer
                    pageTitle="Account"
                    page={<AccountLandingPage />}
                  />
                }
              />
              <Route exact path="/" element={<LandingPage />} />
              <Route
                path="*"
                element={<PageContainer pageTitle="" page={<NotFound />} />}
              />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route exact path="*" element={<SignInPage />} />
            </Routes>
          </UnauthenticatedTemplate>
        </Router>
      </RequestInterceptor>
    </div>
  )
}

export default App
