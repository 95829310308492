export const clientsColumn = [
  {
    field: 'img',
    headerName: ''
  },
  {
    field: 'name',
    headerName: 'Client Name'
  },
  {
    field: 'clientNumber',
    headerName: 'Client Number'
  },
  {
    field: 'clientType',
    headerName: 'Client Type'
  },
  {
    field: 'viewClient',
    headerName: ''
  }
]
