import { Box, Card, CardContent, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PropTypes } from 'prop-types'
import React from 'react'
import { toCurrency } from '../utils'

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(4, 3, 0, 3)
  },
  cardContent: {
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '& h6': {
      fontSize: 20,
      marginBottom: theme.spacing(1.5),
      fontWeight: 600,
      color: '#0F1222'
    },
    '& .date': {
      fontFamily: 'Roboto',
      color: '#696974',
      fontSize: 14,
      letterSpacing: 0.1
    }
  },
  balanceAmount: {
    fontFamily: '"Poppins", "Source Sans Pro", "sans-serif"',
    fontWeight: 700,
    fontSize: 28,
    letterSpacing: '0.125464px',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0
    }
  },
  overdue: {
    color: theme.palette.error.main
  }
}))

const BalanceCard = ({ totalBalance, balanceDue }) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h6">Total Balance: </Typography>
          <Typography fontWeight="bold" variant="h6" component="span">
            {toCurrency(totalBalance)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h6">Past Due Balance: </Typography>
          <Typography
            color={balanceDue > 0 && 'error'}
            fontWeight="bold"
            variant="h6"
            component="span">
            {toCurrency(balanceDue)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

BalanceCard.propTypes = {
  balanceDue: PropTypes.number,
  totalBalance: PropTypes.number
}

export default BalanceCard
