import {
  Divider,
  Box,
  Card,
  Typography,
  Grid,
  useMediaQuery,
  CardContent
} from '@mui/material'
import React from 'react'
import { GenericSidecard, SelectSidecard } from '../components/TraderContact'
import { companyData } from '../data'
import makeStyles from '@mui/styles/makeStyles'
import BalanceCard from '../components/BalanceCard'
import { useRecoilState, useSetRecoilState } from 'recoil'
import Spacer from '../components/Spacer'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import Schedule from '../components/Schedule'
import { useTheme } from '@mui/material/styles'
import MobileTitle from '../components/MobileTitle'
import { logError } from '../services/sentry'
import { useLocation, useParams } from 'react-router-dom'
import { getCustomer, getSupplier } from '../services'
import { LoadingWrapper } from '../components/LoadingWrapper'
import { useSnackbar } from 'notistack'
import { snackBarFailure } from '../theme'
import { handleAxiosError } from '../utils'
import {
  clientDataAtom,
  scheduleAutocompleteFilterAtom,
  scheduleAutocompleteOptionsAtom
} from '../recoil/data/atom'

const useStyles = makeStyles(theme => ({
  grid: {
    '& h5': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      fontSize: 16
    },
    '& h4': {
      fontWeight: 700
    }
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '& h6': {
      fontSize: 20,
      fontWeight: 600,
      color: '#0F1222'
    },
    '& .date': {
      fontFamily: 'Roboto',
      color: '#696974',
      fontSize: 14,
      letterSpacing: 0.1
    }
  },
  accountImg: {
    height: 46,
    [theme.breakpoints.up('md')]: {
      height: 56,
      marginTop: theme.spacing(2)
    }
  },

  gridOne: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(4)
    }
  },
  card: {
    height: '100%',
    position: 'relative',
    padding: theme.spacing(4, 3, 0, 3)
  }
}))

const HomePage = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [clientData, setClientData] = useRecoilState(clientDataAtom)
  const [loading, setLoading] = React.useState(false)
  const theme = useTheme()
  const calendarRef = React.useRef()
  const desktop = useMediaQuery(theme.breakpoints.up('lg'))
  const { id } = useParams()
  const { pathname } = useLocation()
  const userType = pathname.includes('Suppliers') ? 'supplier' : 'customer'
  const setAutocompleteOptions = useSetRecoilState(
    scheduleAutocompleteOptionsAtom
  )
  const setAutocompleteFilter = useSetRecoilState(
    scheduleAutocompleteFilterAtom
  )

  const handleReceivedData = () => {
    console.log('handle received data')
    let calendarApi = calendarRef?.current?.getApi()
    const events = calendarApi?.getEvents()
    const formatted = events?.map(x => ({ ...x?._def, ...x?._instance }))
    const sorted = formatted
      ?.sort((a, b) => {
        if (a.extendedProps?.unitOfMeasure) {
          return 1
        }
        return -1
      })
      ?.sort(
        (a, b) =>
          b.extendedProps[`${userType}ContractLineNumber`] -
          a.extendedProps[`${userType}ContractLineNumber`]
      )
      ?.sort(
        (a, b) =>
          b.extendedProps[`${userType}ContractNumber`] -
          a.extendedProps[`${userType}ContractNumber`]
      )
      ?.sort((a, b) => a.range.end - b.range.end)
    const uniqueArray = [
      ...new Map(sorted?.map(item => [item['title'], item])).values()
    ]
    setAutocompleteOptions(uniqueArray)
    setAutocompleteFilter(uniqueArray)
  }

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        if (pathname.includes('Suppliers')) {
          const response = await getSupplier(id)
          await setClientData(response)
          setLoading(false)
        } else if (pathname.includes('Customers')) {
          const response = await getCustomer(id)
          await setClientData(response)
          setLoading(false)
        }
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
        setLoading(false)
      }
    }
    if (!clientData) {
      getData()
    }
  }, [])

  return (
    <Grid
      className={classes.grid}
      alignItems="flex-start"
      spacing={4}
      container>
      <Grid
        lg={8}
        xs={12}
        item
        className={classes.gridOne}
        spacing={2}
        container>
        <MobileTitle title="Home" />
        <Grid item xs={12}>
          <Typography align="left" variant="h5">
            {process.env.REACT_APP_HELLO}
            Overview
          </Typography>
        </Grid>
        <Grid lg={6} item xs={12}>
          <Card className={classes.card}>
            <LoadingWrapper visible={loading}>
              <CardContent className={classes.cardContent}>
                <Box alignItems="center" display="flex">
                  {clientData?.imageId ? (
                    <img
                      className={classes.accountImg}
                      src={
                        process.env.REACT_APP_API_URL +
                        '/Media/tenantMedia/' +
                        clientData?.imageId
                      }
                      alt="account"
                    />
                  ) : (
                    <WorkOutlineIcon color="primary" fontSize="large" />
                  )}
                  <Spacer />
                  <Typography textAlign="left" variant="h4">
                    {clientData?.name}
                  </Typography>
                </Box>
              </CardContent>
            </LoadingWrapper>
          </Card>
        </Grid>
        <Grid position="relative" lg={6} item xs={12}>
          <LoadingWrapper visible={loading}>
            <BalanceCard
              totalBalance={clientData?.totalBalance}
              balanceDue={clientData?.balanceDue}
            />
          </LoadingWrapper>
        </Grid>
        <Grid item xs={12}>
          <Schedule
            handleReceivedData={handleReceivedData}
            calendarRef={calendarRef}
            home
          />
        </Grid>
      </Grid>
      {desktop && <Divider flexItem orientation="vertical" />}
      <Grid item lg={4} mb={!desktop ? 12 : 0} rowSpacing={2} container>
        <>
          <Grid item xs={12}>
            <Typography align="left" variant="h5">
              Contact Us
            </Typography>
          </Grid>
          {clientData?.trader && (
            <Grid item xs={12}>
              <SelectSidecard loading={loading} {...clientData?.trader} />
            </Grid>
          )}
          <Grid item xs={12}>
            <GenericSidecard {...companyData} />
          </Grid>
        </>
      </Grid>
    </Grid>
  )
}

export default HomePage
