import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import scenery from '../assets/scenery.jpeg'
import logo from '../assets/wws-trading-white.png'
import Button from '../components/Button'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import { useIsAuthenticated } from '@azure/msal-react'
import Spacer from '../components/Spacer'
import { snackBarFailure } from '../theme'
import { useSnackbar } from 'notistack'
import { logError } from '../services/sentry'
import { isMobile } from 'react-device-detect'
import { SnackBarInfo } from '../components/Snackbars'

const useStyles = makeStyles(theme => ({
  container: {
    '& h2': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6)
      }
    }
  },
  contentGrid: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 0
    }
  },
  logo: {
    height: 41
  },
  userIcon: {
    width: 24,
    marginRight: theme.spacing(1.5)
  },
  email: {
    color: '#263238'
  },
  input: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1.5)
  },
  link: {
    placeSelf: 'flex-end',
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  imageGrid: {
    '& img': {
      position: 'absolute',
      objectFit: 'cover',
      right: 0,
      top: 0,
      height: 300,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        height: '100vh',
        position: 'unset'
      }
    }
  }
}))

const SignInPage = () => {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const { enqueueSnackbar } = useSnackbar()

  const handleLogin = instance => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e)
    })
  }
  if (isMobile) {
    enqueueSnackbar('For the best experience, use site on desktop.', {
      persist: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      content: (key, message) => (
        <SnackBarInfo variant="info" id={key} message={message} />
      )
    })
  }

  const handleLogout = instance => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: '/'
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(e)
      })
  }

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid
          item
          container
          alignItems="center"
          md={6}
          xs={12}
          className={classes.contentGrid}>
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            width="max-content"
            ml="auto"
            mr="auto"
            mt={desktop ? 0 : '300px'}
            mb={desktop ? 8 : 0}
            pl={2}
            pr={2}
            pt={desktop ? 0 : 8}>
            <img className={classes.logo} src={logo} alt="logo" />
            <Typography component="h2" variant="h4">
              {!isAuthenticated && 'Sign In'}
            </Typography>
            <Typography textAlign="left">
              {!isAuthenticated
                ? 'Welcome back! click the button below to login through Microsoft'
                : 'You are Signed in'}
            </Typography>
            <Spacer m={2} />
            {isAuthenticated ? (
              <Button onClick={() => handleLogout(instance)}>Log out</Button>
            ) : (
              <Button onClick={() => handleLogin(instance)}>Sign In</Button>
            )}
          </Box>
        </Grid>
        <Grid item className={classes.imageGrid} md={6} xs={12}>
          <img alt="farm at sunset" src={scenery} />
        </Grid>
      </Grid>
    </div>
  )
}
export default SignInPage
