import { Badge, Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import React from 'react'
import Icons from '@thesparklaboratory/react-myicons'
import { makeStyles, withStyles } from '@mui/styles'
import { handleAxiosError, standardDateFormat } from '../utils/index'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  updateCustomerNotifications,
  updateSupplierNotifications
} from '../services'
import { preSearchEventAtom } from '../recoil/schedule/atom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { PropTypes } from 'prop-types'
import { snackBarFailure } from '../theme'
import { useSnackbar } from 'notistack'
import { logError } from '../services/sentry'
import { locationFilterAtom } from '../recoil/data'
import { locationListAtom } from '../recoil/data/atom'

const { calendarScheduleCheckmark: ScheduleIcon } = Icons.interfaceEssential
const { invoiceAccounting1: InvoiceIcon } = Icons.paymentsFinance

const StyledMenuItem = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 3),
    background: theme.palette.background.paper,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiListItemText-primary': {
      color: '#101C2A'
    },
    '& .MuiListItemText-secondary': {
      color: '#9FA1B0'
    },
    '& .MuiListItemIcon-root': {
      borderRadius: '50%',
      '& svg': {
        width: 32
      },
      background: '#F8FAFB',
      marginRight: theme.spacing(2),
      padding: theme.spacing(1)
    }
  }
}))(MenuItem)

const useStyles = makeStyles(theme => ({
  badge: {
    '& svg': {
      width: 30,
      color: '#92929D'
    }
  },
  styledMenu: {
    '& svg': {
      width: 24
    }
  },
  icons: {
    color: theme.palette.primary.main
  },
  notificationButton: {
    padding: 6
  },
  listItemText: {
    textAlign: 'left',
    whiteSpace: 'break-spaces',
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'unset'
    }
  },
  listItemIcon: {
    height: 'fit-content'
  }
}))

const NotificationComponent = ({
  notificationType,
  documentNumber,
  isAcknowledged,
  eventDate,
  handleClose = () => null,
  lineNumber,
  isLoadDeleted,
  loadDetails,
  component
}) => {
  const classes = useStyles()
  const [icon, setIcon] = React.useState(null)
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [route, setRoute] = React.useState(null)
  const { pathname } = useLocation()
  const { id } = useParams()
  const setPreSearchEvent = useSetRecoilState(preSearchEventAtom)
  const { enqueueSnackbar } = useSnackbar()
  const setLocationFilter = useSetRecoilState(locationFilterAtom)
  const locationList = useRecoilValue(locationListAtom)
  const userType = pathname.includes('Suppliers') ? 'Suppliers' : 'Customers'

  const navigate = useNavigate('')

  React.useEffect(() => {
    setData()
  }, [notificationType, documentNumber])

  const setData = () => {
    switch (notificationType) {
      case 'NEW_CONTRACT_SUPPLIER':
        setTitle('New Contract')
        setDescription(`Contract ${documentNumber} is ready for review `)
        setRoute(
          `/${userType}/Contracts/${id}?documentNumber=${documentNumber}`
        )
        return setIcon(<ScheduleIcon className={classes.icons} />)
      case 'NEW_CONTRACT_CUSTOMER':
        setTitle('New Contract')
        setDescription(`Contract ${documentNumber} is ready for review `)
        setRoute(
          `/${userType}/Contracts/${id}?documentNumber=${documentNumber}`
        )
        return setIcon(<ScheduleIcon className={classes.icons} />)
      case 'NEW_INVOICE_CUSTOMER':
        setTitle('New Invoice')
        setDescription(`Invoice ${documentNumber} is ready for review `)
        setRoute(`/${userType}/Invoices/${id}?documentNumber=${documentNumber}`)
        return setIcon(<InvoiceIcon className={classes.icons} />)
      case 'INVOICE_DUE_SOON_CUSTOMER':
        setTitle('Invoice Due Soon')
        setDescription(
          `Invoice ${documentNumber} is due ${standardDateFormat(eventDate)} `
        )
        setRoute(`/${userType}/Invoices/${id}?documentNumber=${documentNumber}`)
        return setIcon(<InvoiceIcon className={classes.icons} />)
      case 'INVOICE_OVERDUE_CUSTOMER':
        setTitle('Invoice Overdue')
        setDescription(
          `Invoice ${documentNumber} was due on ${standardDateFormat(
            eventDate
          )} `
        )
        setRoute(`/${userType}/Invoices/${id}?documentNumber=${documentNumber}`)
        return setIcon(<InvoiceIcon className={classes.icons} />)
      case 'SCHEDULE_CHANGE_CUSTOMER':
        setTitle(`${loadDetails} has been updated`)
        setRoute(
          `/${userType}/Schedule/${id}?documentNumber=${documentNumber}&lineNumber=${lineNumber}`
        )
        if (isLoadDeleted) {
          setDescription(
            `A load has been removed from Contract ${documentNumber}`
          )
        } else {
          setDescription('Please view the load for more information')
        }
        return setIcon(<InvoiceIcon className={classes.icons} />)
      case 'SCHEDULE_CHANGE_SUPPLIER':
        setTitle(`${loadDetails} has been updated`)
        setRoute(
          `/${userType}/Schedule/${id}?documentNumber=${documentNumber}&lineNumber=${lineNumber}`
        )
        if (isLoadDeleted) {
          setDescription(
            `A load has been removed from Contract ${documentNumber}`
          )
        } else {
          setDescription('Please view the load for more information')
        }
        return setIcon(<InvoiceIcon className={classes.icons} />)
      default:
        return setIcon(null)
    }
  }

  const handleClick = async () => {
    handleClose()
    if (
      notificationType === 'SCHEDULE_CHANGE_CUSTOMER' ||
      notificationType === 'SCHEDULE_CHANGE_SUPPLIER'
    ) {
      if (locationList.length) {
        await setLocationFilter(locationList)
      }
      debugger
      setPreSearchEvent({
        documentNumber: documentNumber,
        lineNumber: lineNumber
      })
    }
    try {
      if (userType === 'Suppliers') {
        await updateSupplierNotifications({
          notificationType,
          lineNumber,
          documentNumber,
          isAcknowledged: true,
          eventDate
        })
      } else {
        await updateCustomerNotifications({
          notificationType,
          documentNumber,
          lineNumber,
          isAcknowledged: true,
          eventDate
        })
      }
      navigate(route)
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
      logError(error)
    }
  }

  return (
    <StyledMenuItem component={component} onClick={handleClick}>
      <Box display="flex">
        <ListItemIcon className={classes.listItemIcon}>
          <Badge color="error" variant="dot" invisible={isAcknowledged}>
            {icon}
          </Badge>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          secondary={description}
          primary={title}
        />
      </Box>
      {/* // ** Might come back  */}
      {/* {!menu && (
        <Box display="flex">
          <Typography fontStyle="italic">
            {moment(eventDate).format('dddd, MMMM DD, YYYY hh:mma')}
          </Typography>
        </Box>
      )} */}
    </StyledMenuItem>
  )
}

NotificationComponent.propTypes = {
  notificationType: PropTypes.string.isRequired,
  component: PropTypes.string,
  documentNumber: PropTypes.string.isRequired,
  loadDetails: PropTypes.string,
  isAcknowledged: PropTypes.bool.isRequired,
  eventDate: PropTypes.string.isRequired
}

export default NotificationComponent
