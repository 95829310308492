import React from 'react'
import { makeStyles } from '@mui/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { isFuture, parse } from 'date-fns'
import Icons from '@thesparklaboratory/react-myicons'
import { useSnackbar } from 'notistack'
import theme, { snackBarFailure } from '../theme'
import { useParams, useSearchParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { handleAxiosError, toCurrency } from '../utils'
import { dateOnlyFormatter } from '../utils/gridFunctions'
import { useRecoilState } from 'recoil'
import { getSecureDocumentUrl } from '../services'
import { CustomToolbar } from './CustomToolbar'
import {
  invoiceDataGridAtom,
  invoiceLimitAtom,
  invoiceFilterModelAtom,
  invoiceSortModelAtom
} from '../recoil/data'
import { useDidUpdateEffect } from '../hooks/useDidUpdateEffect'
import fastDeepEqual from 'fast-deep-equal'
const { documentContent15: DocumentIcon } = Icons.contentEdit

const useStyles = makeStyles(theme => ({
  viewPDF: {
    '& svg': {
      width: 24
    }
  },
  spacer: {
    height: theme.spacing(1.5)
  },
  dataRow: {
    background: theme.palette.background.paper
  },
  dropDownRow: {
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
    '& td': {
      color: '#0F1222'
    },
    '& tbody': {
      '& tr:nth-child(odd)': {
        background: theme.palette.background.default
      }
    }
  },
  tableHeading: {
    '& th': {
      color: '#535875',
      opacity: 0.84
    }
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  documentNumber: {
    color: '#0f1222'
  },
  error: {
    fontSize: 14,
    color: '#FC5A5A'
  },
  overdue: {
    fontSize: 16,
    fontWeight: 600
  }
}))

const InvoiceDataTable = props => {
  const { service, columns, ...dataGridProps } = props
  const classes = useStyles()
  const [data, setData] = useRecoilState(invoiceDataGridAtom)
  const [limit, setLimit] = useRecoilState(invoiceLimitAtom)
  const [filterModel, setFilterModel] = useRecoilState(invoiceFilterModelAtom)
  const [sortModel, setSortModel] = useRecoilState(invoiceSortModelAtom)
  const [searchParams] = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const [colType, setColType] = React.useState([])
  const dataReset = () => {
    setData({
      docs: [],
      pages: {},
      loading: true,
      page: 0,
      totalDocs: 0
    })
  }

  React.useEffect(() => {
    const documentNumber = searchParams.get('documentNumber')
    if (documentNumber) {
      const newModel = {
        items: [],
        quickFilterValues: [documentNumber],
        quickFilterLogicOperator: 'and'
      }
      // Detect substantive change to avoid infinite loop
      if (!fastDeepEqual(newModel, filterModel)) {
        setFilterModel(newModel)
      }
    }
  }, [searchParams])

  const desktop = useMediaQuery(theme.breakpoints.up('xl'))

  React.useEffect(() => {
    if (desktop) {
      setColType([
        { flex: 2 },
        { flex: 1 },
        { flex: 1 },
        { flex: 1 },
        { flex: 1 }
      ])
    } else {
      setColType([
        { minWidth: 300 },
        { minWidth: 200 },
        { minWidth: 200 },
        { minWidth: 200 },
        { minWidth: 200 }
      ])
    }
  }, [desktop])

  const definedCols = [
    {
      ...columns[0],
      ...colType[0],
      headerAlign: 'left',
      flex: 2,
      align: 'left',
      type: 'number',
      renderCell: params => {
        const theDate = parse(params.row.dueAt, 'yyyy-MM-dd', new Date())
        const overdue = !isFuture(theDate) && params.row.balanceDue > 0
        if (overdue) {
          return (
            <Box alignItems="center" display="inline-flex">
              <Box mr={1}>
                <ErrorIcon
                  sx={{ verticalAlign: 'text-bottom' }}
                  fontSize="small"
                  color="error"
                />
              </Box>
              <Typography className={classes.overdue} color="error">
                Overdue:&nbsp;
              </Typography>
              {params.formattedValue}
            </Box>
          )
        } else {
          return params.formattedValue
        }
      }
    },
    {
      ...columns[2],
      ...colType[1],
      type: 'date',
      flex: 1,

      valueFormatter: dateOnlyFormatter,
      renderCell: params => {
        const theDate = parse(params.value, 'yyyy-MM-dd', new Date())
        const overdue = !isFuture(theDate) && params.row.balanceDue > 0
        return (
          <Box className={overdue && classes.error}>
            {params.formattedValue}
          </Box>
        )
      }
    },
    {
      ...columns[3],
      ...colType[2],
      flex: 1,
      type: 'number',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return toCurrency(params.row.totalBalance)
      }
    },
    {
      ...columns[4],
      ...colType[3],
      flex: 1,
      headerAlign: 'left',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      align: 'left',
      type: 'number',
      renderCell: params => {
        return toCurrency(params.row.balanceDue)
      }
    },
    {
      ...columns[5],
      ...colType[4],
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: params => {
        if (params.row.documentUrl) {
          return (
            <Box className={classes.viewPDF}>
              <Button
                onClick={() => {
                  let setWindow = window.open('', '_blank')
                  getSecureDocumentUrl(params.row.documentUrl).then(
                    secureUrl => (setWindow.location.href = secureUrl)
                  )
                }}
                startIcon={<DocumentIcon />}
                variant="outlined">
                {' '}
                View PDF
              </Button>
            </Box>
          )
        } else {
          return (
            <Typography sx={{ opacity: 0.5 }} fontStyle="italic">
              PDF not available
            </Typography>
          )
        }
      }
    }
  ]

  React.useEffect(() => {
    // utilize caching to speed up pagination support
    if (data.pages[data.page]) {
      setData(prev => ({ ...prev, docs: data.pages[data.page] }))
      return
    }
    let active = true
    ;(async () => {
      setData(prev => ({ ...prev, loading: true }))
      const results = await service(
        id,
        data.page + 1,
        limit,
        sortModel,
        filterModel
      )

      if (!active) {
        return
      }

      const pages = Object.assign({}, data.pages)
      pages[data.page] = results.docs
      setData(prev => ({
        ...prev,
        loading: false,
        docs: results.docs,
        totalDocs: results.totalDocs,
        pages
      }))
    })().catch(e => {
      const errorMessage = handleAxiosError(e)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    })
    return () => {
      active = false
    }
  }, [data.page, enqueueSnackbar, data.pages])

  // Reset the data when limit, filterModel or sortModel changes

  useDidUpdateEffect(() => {
    dataReset()
  }, [limit, filterModel, sortModel])

  return (
    <DataGridPro
      // key={count}
      autoHeight={true}
      disableColumnMenu
      initialState={{
        filter: { filterModel: filterModel },
        sorting: { sortModel: sortModel }
      }}
      sortModel={sortModel}
      filterModel={filterModel}
      sx={{
        '& .MuiDataGrid-iconButtonContainer': {
          visibility: 'visible',
          width: 'auto'
        },
        '&.MuiDataGrid-cell': { fontSize: 14 },
        '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
          {
            opacity: 0.5
          }
      }}
      {...dataGridProps}
      columns={definedCols}
      filterMode="server"
      getRowId={row => row.documentNumber}
      loading={data.loading}
      page={data.page}
      components={{
        Toolbar: CustomToolbar
      }}
      pageSize={limit}
      pagination
      paginationMode="server"
      rowCount={data.totalDocs}
      disableMultipleSelection={true}
      rows={data.docs}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      sortingMode="server"
      onFilterModelChange={model => {
        if (model.quickFilterValues.length) {
          searchParams.set('documentNumber', model.quickFilterValues[0])
        } else {
          searchParams.delete('documentNumber')
        }
        setFilterModel(model)
      }}
      onPageChange={page => setData(prev => ({ ...prev, page }))}
      onPageSizeChange={n => {
        setLimit(n)
      }}
      onSortModelChange={model => {
        setSortModel(model)
      }}
    />
  )
}

InvoiceDataTable.propTypes = {
  service: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired
}

export default InvoiceDataTable
