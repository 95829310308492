import { IconButton } from '@mui/material'
import React from 'react'
import { PropTypes } from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'

export const ModalCloseButton = ({ handleClose }) => {
  return (
    <IconButton sx={{ mr: -1 }} onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  )
}

ModalCloseButton.propTypes = {
  handleClose: PropTypes.func
}

export default ModalCloseButton
