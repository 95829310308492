import React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Tooltip, useMediaQuery } from '@mui/material'
import { useSnackbar } from 'notistack'
import theme, { snackBarFailure, snackBarSuccess } from '../theme'
import EditUserModal from './EditUserModal'
import { logError } from '../services/sentry'
import { PropTypes } from 'prop-types'
import { resendInvitation } from '../services'
import { handleAxiosError } from '../utils'
import { ResendInvitationModal } from './ResendInvitationModal'
import { CustomToolbar } from './CustomToolbar'
import { useRecoilState } from 'recoil'
import { userFilterModelAtom, usersDataGridAtom } from '../recoil/data'

const UsersDataTable = props => {
  const {
    service,
    refresh,
    triggerRefresh = () => null,
    previousRefresh,
    columns,
    ...dataGridProps
  } = props

  const { enqueueSnackbar } = useSnackbar()
  const [colType, setColType] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const desktop = useMediaQuery(theme.breakpoints.up('xl'))
  const [filterModel, setFilterModel] = useRecoilState(userFilterModelAtom)

  React.useEffect(() => {
    if (desktop) {
      setColType([{ flex: 1 }, { flex: 3 }, { flex: 1 }])
    } else {
      setColType([{ minWidth: 300 }, { minWidth: 300 }, { minWidth: 250 }])
    }
  }, [desktop])

  const handleInvitation = async data => {
    try {
      setLoading(true)
      await resendInvitation(data)
      setLoading(false)
      enqueueSnackbar('Invitation sent', {
        variant: 'success',
        sx: snackBarSuccess
      })
    } catch (error) {
      setLoading(false)
      const errorMessage = handleAxiosError(error)
      logError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    }
  }

  const [data, setData] = useRecoilState(usersDataGridAtom)

  React.useEffect(() => {
    // utilize caching to speed up pagination support
    if (data.pages[data.page] && previousRefresh === refresh) {
      setData(prev => ({ ...prev, docs: data.pages[data.page] }))
      return
    }

    let active = true
    ;(async () => {
      setData(prev => ({ ...prev, loading: true }))
      const results = await service(
        data.page + 1,
        data.limit,
        data.sort,
        data.filter
      )
      if (!active) {
        return
      }

      const pages = Object.assign({}, data.pages)
      pages[data.page] = results.docs
      setData(prev => ({
        ...prev,
        loading: false,
        docs: results.docs,
        totalDocs: results.totalDocs,
        pages
      }))
    })().catch(error => {
      const errorMessage = handleAxiosError(error)
      logError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    })
    return () => {
      active = false
    }
  }, [
    data.page,
    enqueueSnackbar,
    data.limit,
    data.sort,
    data.filter,
    data.pages,
    service,
    refresh
  ])

  const definedCols = [
    {
      ...columns[2],
      ...colType[0]
    },
    {
      ...columns[3],
      ...colType[1],
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => {
        const content = params?.row?.clients?.map((x, i) => (
          <span key={i}>
            {i > 0 && '; '}
            {x?.name}
          </span>
        ))
        return (
          <Tooltip title={content}>
            <div className="MuiDataGrid-cellContent">{content}</div>
          </Tooltip>
        )
      }
    },
    {
      ...columns[4],
      ...colType[2],
      align: 'right',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: params => {
        if (data) {
          const handleClick = () => {
            handleInvitation({
              email: params.row?.email,
              displayName: params?.row?.displayName
            })
          }
          return (
            <Box gap={2} mr={2} display="flex" justifyContent="flex-end">
              {params?.row?.externalUserState !== 'Accepted' &&
                !params?.row?.isInternal && (
                  <ResendInvitationModal
                    loading={loading}
                    {...params?.row}
                    handleClick={handleClick}
                  />
                )}
              <EditUserModal triggerRefresh={triggerRefresh} {...params} />
            </Box>
          )
        }
      }
    }
  ]

  return (
    <DataGridPro
      initialState={
        filterModel
          ? {
              filter: {
                filterModel
              }
            }
          : {}
      }
      autoHeight={true}
      disableColumnMenu
      sx={{
        '& .MuiDataGrid-iconButtonContainer': {
          visibility: 'visible',
          width: 'auto'
        },
        '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
          {
            opacity: 0.5
          }
      }}
      {...dataGridProps}
      columns={definedCols}
      filterMode="server"
      getRowId={row => row.userId}
      loading={data.loading}
      page={data.page}
      pageSize={data.limit}
      pagination
      components={{
        Toolbar: CustomToolbar
      }}
      paginationMode="server"
      disableMultipleSelection={true}
      rowCount={data.totalDocs}
      rows={data.docs}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      sortingMode="server"
      onFilterModelChange={model => {
        setFilterModel(model)
        setData(prev => ({
          ...prev,
          filter: model,
          pages: {},
          page: 0
        }))
      }}
      onPageChange={page => setData(prev => ({ ...prev, page }))}
      onPageSizeChange={limit =>
        setData(prev => ({ ...prev, limit, pages: {}, page: 0 }))
      }
      onSortModelChange={model =>
        setData(prev => ({
          ...prev,
          sort: model,
          pages: {},
          page: 0
        }))
      }
    />
  )
}

UsersDataTable.propTypes = {
  service: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired
}

export default UsersDataTable
