import React from 'react'
import { Box } from '@mui/material'
import { PropTypes } from 'prop-types'

const Spacer = ({ ...props }) => {
  return <Box {...props}></Box>
}

Spacer.defaultProps = {
  m: 1
}

Spacer.propTypes = {
  m: PropTypes.number
}

export default Spacer
