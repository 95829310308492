import React, { forwardRef, useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import { useSnackbar, SnackbarContent } from 'notistack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.info.main,
    fontSize: 12,
    width: '100%',
    padding: 0,
    fontFamily: 'Roboto'
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between'
  },
  icons: {
    marginLeft: 'auto'
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: 'all .2s'
  },
  button: {
    padding: 0,
    textTransform: 'none'
  }
}))

export const SnackBarInfo = forwardRef((props, ref) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id)
  }, [props.id, closeSnackbar])

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <ErrorOutlineIcon color="primary" />
          <Typography
            fontFamily="Roboto"
            fontSize={12}
            ml={2}
            className={classes.typography}>
            {props.message}
          </Typography>
          <div className={classes.icons}>
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  )
})
