export const supplierContractColumns = [
  {
    field: 'documentNumber',
    headerName: 'Contract Number'
  },
  {
    field: 'loadCount',
    headerName: '# of Loads'
  },
  {
    field: 'description',
    headerName: 'Product Description'
  },
  {
    field: 'locationCode',
    headerName: 'Pick Up Location'
  },
  {
    field: 'documentedAt',
    headerName: 'Contract Date'
  },
  {
    field: 'pdf',
    headerName: ''
  }
]

export const customerContractColumns = [
  {
    field: 'documentNumber',
    headerName: 'Contract Number'
  },
  {
    field: 'loadCount',
    headerName: '# of Loads'
  },
  {
    field: 'description',
    headerName: 'Product Description'
  },
  {
    field: 'locationCode',
    headerName: 'Drop Off Location'
  },
  {
    field: 'documentedAt',
    headerName: 'Contract Date'
  },
  {
    field: 'pdf',
    headerName: ''
  }
]

export const customerSubColumns = [
  {
    field: 'description',
    headerName: 'Description'
  },
  {
    field: 'unitPrice',
    headerName: 'Unit Price'
  },
  {
    field: 'quantity',
    headerName: 'Quantity'
  },
  {
    field: 'unitOfMeasure',
    headerName: 'Unit'
  },
  {
    field: 'loadNumber',
    headerName: 'Load #'
  },
  {
    field: 'customerPurchaseOrderNumber',
    headerName: 'Customer PO #'
  },
  {
    field: 'dropoffLocation',
    headerName: 'Drop Off Location'
  },
  {
    field: 'dropoffAt',
    headerName: 'Drop off Date'
  },
  {
    field: 'dropoffTime',
    headerName: 'ETA'
  },
  {
    field: 'schedule',
    headerName: 'Sched. Not Confirmed'
  }
]

export const supplierSubColumns = [
  {
    field: 'description',
    headerName: 'Description'
  },
  {
    field: 'unitCost',
    headerName: 'Unit Cost'
  },
  {
    field: 'quantity',
    headerName: 'Quantity'
  },
  {
    field: 'unitOfMeasure',
    headerName: 'Unit'
  },
  {
    field: 'loadNumber',
    headerName: 'Load #'
  },
  {
    field: 'supplierSalesOrderNumber',
    headerName: 'Supplier SO #'
  },
  {
    field: 'pickupLocation',
    headerName: 'Pick Up Location'
  },
  {
    field: 'pickupAt',
    headerName: 'Pick Up Date'
  },
  {
    field: 'pickupTime',
    headerName: 'Time'
  },
  {
    field: 'schedule',
    headerName: 'Sched. Not Confirmed'
  }
]
