/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Modal,
  Typography,
  TextField,
  FormControl,
  IconButton,
  Grid,
  MenuItem
} from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Controller, useForm } from 'react-hook-form'
import EditIcon from '@mui/icons-material/Edit'
import Button from './Button'
import { useSnackbar } from 'notistack'
import { deleteUser, editUser } from '../services'
import { snackBarFailure, snackBarSuccess } from '../theme'
import { logError } from '../services/sentry'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRecoilValue } from 'recoil'
import { companyListAtom } from '../recoil/company'
import Autocomplete from './AutoComplete'
import { PropTypes } from 'prop-types'
import { userAtom } from '../recoil/user/atom'
import { ModalCloseButton } from './ModalCloseButton'
import { handleAxiosError } from '../utils'
import Link from './Link'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  form: {
    width: '100%',
    textAlign: 'center',
    margin: theme.spacing(3, 0)
  }
}))

export const EditUserModal = ({ row, triggerRefresh = () => null }) => {
  const companyList = useRecoilValue(companyListAtom)
  const user = useRecoilValue(userAtom)
  const [loading, setLoading] = React.useState(false)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const { enqueueSnackbar } = useSnackbar()

  const schema = yup.object().shape({
    email: yup.string().matches(/.+@.+\..+/, 'Email is invalid')
  })

  const transformClients = row?.clients?.map((x, i) => {
    if (x?.supplierNumber) {
      return {
        clientNumber: x?.supplierNumber,
        clientType: 'Supplier',
        name: x?.name
      }
    } else if (x?.customerNumber) {
      return {
        clientNumber: x?.customerNumber,
        clientType: 'Customer',
        name: x?.name
      }
    }
    return null
  })

  const defaultValues = {
    email: row?.email,
    clients: transformClients,
    role: row?.isSuperAdmin ? 'isSuperAdmin' : row?.isAdmin ? 'isAdmin' : 'none'
  }

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({ resolver: yupResolver(schema), defaultValues })

  const onSubmit = async ({ clients, role, email }) => {
    const patchData = {
      clients: clients,
      isSuperAdmin: role === 'isSuperAdmin' ? true : false,
      isAdmin: role === 'isAdmin' || role === 'isSuperAdmin' ? true : false
    }
    try {
      setLoading(true)
      await editUser(email, patchData)
      enqueueSnackbar('Edit Complete', {
        variant: 'success',
        sx: snackBarSuccess,
        persist: true
      })
      handleClose()
      setLoading(false)
      triggerRefresh()
    } catch (e) {
      logError(e)
      setLoading(false)
      const errorMessage = handleAxiosError(e)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    }
  }

  const handleDelete = async row => {
    try {
      setLoading(true)
      await deleteUser(row?.email)
      setLoading(false)
      enqueueSnackbar('User Removed', {
        variant: 'success',
        sx: snackBarSuccess
      })
      handleClose()
      triggerRefresh()
    } catch (e) {
      logError(e)
      setLoading(false)
      const errorMessage = handleAxiosError(e)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    }
  }

  return (
    <>
      <IconButton variant="text" onClick={handleOpen}>
        <EditIcon color="primary" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-user-modal-title"
        aria-describedby="edit-user-modal-description">
        <Box className={classes.paper}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            justifyContent="space-between">
            <Typography color="secondary" variant="h5">
              Edit User
            </Typography>
            <ModalCloseButton handleClose={handleClose} />
          </Box>
          <FormControl className={classes.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid alignItems="center" container rowSpacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors?.email)}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        helperText={errors?.email?.message}
                        label="Email Address"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {user?.isSuperAdmin && row?.isInternal && (
                  <Grid item xs={12}>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          select
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={value}
                          onChange={onChange}
                          label="role"
                          margin="normal"
                          variant="outlined">
                          <MenuItem value="none">Client</MenuItem>
                          <MenuItem value="isAdmin">Admin</MenuItem>
                          <MenuItem value="isSuperAdmin">Super Admin</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Autocomplete
                    margin="normal"
                    fullWidth
                    label="Client"
                    errors={errors}
                    options={companyList || []}
                    name="clients"
                    control={control}
                  />
                </Grid>
                <Grid textAlign="left" item xs={12}>
                  <Link underline="hover" to="/Account" variant="text">
                    Manage Notification Preferences
                  </Link>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Button loading={loading} type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormControl>
          <Box textAlign="center">
            <Button
              loading={loading}
              variant="text"
              onClick={() => handleDelete(row)}
              color="error">
              Remove User
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

EditUserModal.propTypes = {
  row: PropTypes.object.isRequired,
  triggerRefresh: PropTypes.func
}

export default EditUserModal
