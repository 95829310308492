import React from 'react'
import { makeStyles } from '@mui/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import Icons from '@thesparklaboratory/react-myicons'
import { useSnackbar } from 'notistack'
import { snackBarFailure } from '../theme'
import { useNavigate } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import Link from './Link'
import { handleAxiosError } from '../utils'
import { logError } from '../services/sentry'
import { CustomToolbar } from './CustomToolbar'
import { isMobile } from 'react-device-detect'
import { useRecoilState } from 'recoil'
import { clientDataGridAtom, clientFilterModelAtom } from '../recoil/data'
const { documentContent15: DocumentIcon } = Icons.contentEdit

const useStyles = makeStyles(theme => ({
  viewPDF: {
    '& svg': {
      width: 24
    }
  },
  logo: {
    width: 60
  }
}))

const ClientsDataTable = props => {
  const theme = useTheme()
  const [colType, setColType] = React.useState([])
  const { service, columns, ...dataGridProps } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const [filterModel, setFilterModel] = useRecoilState(clientFilterModelAtom)

  const handleViewClient = (id, type) => {
    if (type === 'Customer') {
      return navigate(`/Customers/${id}`)
    }
    return navigate(`/Suppliers/${id}`)
  }

  React.useEffect(() => {
    if (desktop) {
      setColType([{ flex: 1 }, { flex: 1 }, { flex: 1 }, { flex: 1 }])
    } else {
      setColType([
        { minWidth: 320 },
        { minWidth: 150 },
        { minWidth: 150 },
        { minWidth: 200 }
      ])
    }
  }, [desktop])

  const definedCols = [
    {
      ...colType[0],
      width: 200,
      ...columns[1],
      renderCell: params => {
        const { row } = params
        return (
          <Link
            to={
              row.clientType === 'Customer'
                ? `/Customers/${row?.clientNumber}`
                : `/Suppliers/${row?.clientNumber}`
            }>
            {row?.name}
          </Link>
        )
      }
    },
    {
      ...colType[1],
      ...columns[2]
    },
    {
      ...colType[2],
      ...columns[3]
    },
    {
      ...colType[3],
      ...columns[4],
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      align: 'right',
      renderCell: params => {
        const { row } = params
        return (
          <Box
            mr={2}
            flex
            justifyContent="flex-end"
            className={classes.viewPDF}>
            <Button
              onClick={() => handleViewClient(row.clientNumber, row.clientType)}
              startIcon={<DocumentIcon />}
              variant="outlined">
              {' '}
              View Client
            </Button>
          </Box>
        )
      }
    }
  ]

  const [data, setData] = useRecoilState(clientDataGridAtom)

  React.useEffect(() => {
    // utilize caching to speed up pagination support
    if (data.pages[data.page]) {
      setData(prev => ({ ...prev, docs: data.pages[data.page] }))
      return
    }

    let active = true
    ;(async () => {
      setData(prev => ({ ...prev, loading: true }))
      const results = await service(
        data.page + 1,
        data.limit,
        data.sort,
        data.filter
      )
      if (!active) {
        return
      }

      const pages = Object.assign({}, data.pages)
      pages[data.page] = results.docs
      setData(prev => ({
        ...prev,
        loading: false,
        docs: results.docs,
        totalDocs: results.totalDocs,
        pages
      }))
    })().catch(error => {
      const errorMessage = handleAxiosError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
      logError(errorMessage)
    })
    return () => {
      active = false
    }
  }, [
    data.page,
    enqueueSnackbar,
    data.limit,
    data.sort,
    data.filter,
    data.pages,
    service
  ])

  return (
    <DataGridPro
      autoHeight={true}
      disableColumnMenu
      initialState={
        filterModel
          ? {
              filter: {
                filterModel
              },
              columns: {
                columnVisibilityModel: {
                  viewClient: !isMobile
                }
              }
            }
          : {
              columns: {
                columnVisibilityModel: {
                  viewClient: !isMobile
                }
              }
            }
      }
      sx={{
        '& .MuiDataGrid-iconButtonContainer': {
          visibility: 'visible',
          width: 'auto'
        },
        '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
          {
            opacity: 0.5
          }
      }}
      {...dataGridProps}
      columns={definedCols}
      components={{
        Toolbar: CustomToolbar
      }}
      filterMode="server"
      getRowId={row => row.clientNumber + row.clientType}
      loading={data.loading}
      page={data.page}
      pageSize={data.limit}
      pagination
      paginationMode="server"
      rowCount={data.totalDocs}
      rows={data.docs}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      disableMultipleSelection={true}
      sortingMode="server"
      onFilterModelChange={model => {
        setFilterModel(model)
        setData(prev => ({
          ...prev,
          filter: model,
          pages: {},
          page: 0
        }))
      }}
      onPageChange={page => setData(prev => ({ ...prev, page }))}
      onPageSizeChange={limit =>
        setData(prev => ({ ...prev, limit, pages: {}, page: 0 }))
      }
      onSortModelChange={model =>
        setData(prev => ({
          ...prev,
          sort: model,
          pages: {},
          page: 0
        }))
      }
    />
  )
}

ClientsDataTable.propTypes = {
  service: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired
}

export default ClientsDataTable
