import { faker } from '@faker-js/faker'
import axios from 'axios'
import moment from 'moment'
import { INITIAL_EVENTS } from '../data'
import { groupBy, randIndex } from '../utils'

const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Machines

export const getAccountData = async id => {
  return await axios.get(`account/${id}.json?key=cbffd840`)
}

export const getSupplier = async id => {
  const params = {
    include: ['trader', 'locations']
  }

  const { data } = await axios.get(`/Suppliers/${id}`, { params })
  return data
}

export const getCustomer = async id => {
  const params = {
    include: ['trader', 'locations']
  }

  const { data } = await axios.get(`/Customers/${id}`, { params })
  return data
}

export const getUser = async id => {
  const params = {
    include: ['customers', 'suppliers']
  }
  const { data } = await axios.get(`/Users/${id}`, { params: params })
  return data
}

export const getClients = async id => {
  const params = {
    limit: 0
  }
  const { data } = await axios.get(`/Clients`, { params: params })

  const formatNames = data?.items?.map(x => ({
    ...x,
    name:
      x?.clientType === 'Customer'
        ? `${x?.clientNumber} (C) ${x?.name}`
        : `${x?.clientNumber} (S) ${x?.name}`
  }))

  return formatNames
}

export const getClientsGridData = async (page, pageSize, sort, filter) => {
  const params = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    sort: JSON.stringify(sort),
    filter: JSON.stringify(filter)
  }
  const { data } = await axios.get('/Clients', { params: params })
  return {
    docs: data?.items,
    totalDocs: data?.totalItems
  }
}

export const getUsersData = async (page, pageSize, sort, filter) => {
  const params = {
    include: ['customers', 'suppliers'],
    limit: pageSize,
    sort: JSON.stringify(sort),
    filter: JSON.stringify(filter),
    offset: (page - 1) * pageSize
  }

  const { data } = await axios.get('/Users', { params: params })

  const formattedData = data?.items?.map((x, i) => ({
    ...x,
    clients: [...new Set([...x?.suppliers, ...x?.customers])],
    externalUserStateChangeDateTime:
      x?.externalUserStateChangeDateTime &&
      moment(x?.externalUserStateChangeDateTime).format('MMMM DD, YYYY')
  }))

  return {
    docs: formattedData,
    totalDocs: data?.totalItems
  }
}

export const getCustomerInvoiceData = async (
  customerNumber,
  page,
  pageSize,
  sort,
  filter
) => {
  const params = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    sort: JSON.stringify(sort),
    filter: JSON.stringify(filter),
    customerNumber
  }

  const { data } = await axios.get('/CustomerInvoices', { params: params })

  const formattedData = data?.items?.map((x, i) => ({
    ...x
  }))

  return {
    docs: formattedData,
    totalDocs: data?.totalItems
  }
}

export const getSupplierInvoiceData = async (
  supplierNumber,
  page,
  pageSize,
  sort,
  filter
) => {
  const params = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    sort: JSON.stringify(sort),
    filter: JSON.stringify(filter),
    supplierNumber
  }

  const { data } = await axios.get('/SupplierInvoices', { params: params })
  return {
    docs: data?.items,
    totalDocs: data?.totalItems
  }
}

export const getCustomerContractGridData = async (
  customerNumber,
  page,
  pageSize,
  sort,
  filter
) => {
  const params = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    sort: JSON.stringify(sort),
    filter: JSON.stringify(filter),
    include: ['customerContractLines'],
    customerNumber
  }

  const { data } = await axios.get('/CustomerContracts', { params: params })

  const formattedData = data?.items?.map((x, i) => ({
    ...x,
    loadCount: x?.customerContractLines?.length
  }))

  return {
    docs: formattedData,
    totalDocs: data?.totalItems
  }
}

export const getSupplierContractGridData = async (
  supplierNumber,
  page,
  pageSize,
  sort,
  filter
) => {
  const params = {
    limit: pageSize,
    offset: (page - 1) * pageSize,
    include: ['supplierContractLines'],
    sort: JSON.stringify(sort),
    filter: JSON.stringify(filter),
    supplierNumber
  }

  const { data } = await axios.get('/SupplierContracts', { params: params })

  const formattedData = data?.items?.map((x, i) => ({
    ...x,
    loadCount: x?.supplierContractLines?.length,
    description: x?.description2
      ? x?.description + ', ' + x?.description2
      : x?.description
  }))

  return {
    docs: formattedData,
    totalDocs: data?.totalItems
  }
}

export const postFeedback = async data => {
  return await axios.post(`/Feedbacks`, data)
}

export const addUser = async data => {
  return await axios.post(`/Users`, data)
}

export const resendInvitation = async data => {
  return await axios.post(`/Invitations`, data)
}

export const getSupplierNotifications = async (id, page, pageSize) => {
  const params = {
    supplierNumber: id,
    limit: pageSize,
    offset: (page - 1) * pageSize
  }

  const { data } = await axios.get(`/SupplierNotifications`, { params: params })

  return {
    docs: data?.items,
    totalDocs: data?.totalItems
  }
}

export const getCustomerNotifications = async (id, page, pageSize) => {
  const params = {
    customerNumber: id,
    limit: pageSize,
    offset: (page - 1) * pageSize
  }

  const { data } = await axios.get(`/CustomerNotifications`, { params: params })

  return {
    docs: data?.items,
    totalDocs: data?.totalItems
  }
}

export const updateCustomerNotifications = async data => {
  return await axios.post('/CustomerNotifications', data)
}

export const updateSupplierNotifications = async data => {
  return await axios.post('/supplierNotifications', data)
}

export const getNotificationPreferences = async email => {
  const params = {
    email,
    include: ['customerLocation', 'supplierLocation', 'customer', 'supplier']
  }

  const { data } = await axios.get('/NotificationPreferences', {
    params: params
  })

  function buildTableForClient(client, prefsForClient) {
    const clientName =
      prefsForClient[0].customer?.name || prefsForClient[0].supplier?.name
    // console.log("Building table for " + clientName)
    const prefsByLoc = groupBy(prefsForClient, 'locationCode')
    const rows = Object.keys(prefsByLoc)
      ?.sort()
      ?.map(loc => {
        return buildRowForLocation(client, loc, prefsByLoc[loc])
      })
    return {
      clientName: clientName,
      type: prefsForClient[0].customer?.name ? 'Customer' : 'Supplier',
      locations: rows
    }
  }

  function buildRowForLocation(client, loc, prefsForLoc) {
    const locationName = prefsForLoc[0].locationName
    // console.log("Building row for " + locationName, prefsForLoc)
    const row = {
      locationCode: loc,
      locationName: locationName,
      notifications: prefsForLoc
    }
    return row
    // return prefsForLoc
  }

  const allPrefs = data?.items?.map(x => {
    return {
      ...x,
      clientNumber: x.customerNumber ? x.customerNumber : x.supplierNumber,
      locationCode: x.customerLocationCode
        ? x.customerLocationCode
        : x.supplierLocationCode
    }
  })
  const groupedByClientNumber = groupBy(allPrefs, 'clientNumber')

  const formatted = Object.keys(groupedByClientNumber)
    ?.sort()
    ?.map(client => buildTableForClient(client, groupedByClientNumber[client]))
  return formatted
}

export const editNotificationPreferences = async (
  notificationPreferenceId,
  data
) => {
  return await axios.patch(
    `/NotificationPreferences/${notificationPreferenceId}`,
    data
  )
}

export const editUser = async (email, data) => {
  return await axios.patch(`/Users/${email}`, data)
  // delay(500)
  // return {}
}

export const deleteUser = async email => {
  return await axios.delete(`/Users/${email}`)
  // delay(500)
  // return {}
}

export const getCustomerContractLines = async clientNumber => {
  const params = {
    customerNumber: clientNumber,
    limit: 0
  }

  const { data } = await axios.get('/CustomerContractLines', { params: params })

  const formattedData = data?.items?.map((x, i) => ({
    ...x,
    title: x?.customerPurchaseOrderNumber
      ? x?.customerPurchaseOrderNumber
      : '?',
    id: `${x?.customerContractNumber}-${i}`,
    productDescription: x?.description2
      ? x?.description + ' ' + x?.description2
      : x?.description,
    date: x?.dropoffAt,
    allDay: true
  }))

  const sorted = formattedData
    ?.sort((a, b) => {
      if (a.unitOfMeasure) {
        return 1
      }
      return -1
    })
    ?.sort(
      (a, b) => b.customerContractLineNumber - a.customerContractLineNumber
    )
    ?.sort((a, b) => b.customerContractNumber - a.customerContractNumber)
    ?.sort((a, b) => a.dropoffAt - b.dropoffAt)

  const reducer = sorted.reduce(function (acc, cur) {
    const title = cur.title
    const found = acc?.find(function (x) {
      return x.title === title
    })
    if (found && found?.title !== '?') {
      const newArr = acc.filter(object => {
        return object.title !== found.title
      })
      acc = newArr
      let adjustmentObj = {}
      if (!found.unitOfMeasure && found.dropoffAt === cur.dropoffAt) {
        adjustmentObj = {
          adjustment: {
            ...found
          }
        }
        acc.push({ ...cur, ...adjustmentObj })
      } else if (found.dropoffAt === cur.dropoffAt) {
        acc.push({ ...found, ...cur })
      }
    } else {
      acc.push(cur)
    }
    return acc
  }, [])

  return reducer
}

export const getSupplierContractLines = async clientNumber => {
  const params = {
    supplierNumber: clientNumber,
    limit: 0
  }

  const { data } = await axios.get('/SupplierContractLines', { params: params })

  const formattedData = data?.items?.map((x, i) => ({
    ...x,
    title: x?.supplierSalesOrderNumber ? x?.supplierSalesOrderNumber : '?',
    id: `${x?.supplierContractNumber}-${i}`,
    productDescription: x?.description2
      ? x?.description + ' ' + x?.description2
      : x?.description,
    date: x?.pickupAt,
    allDay: true
  }))

  const sorted = formattedData
    ?.sort((a, b) => {
      if (a.unitOfMeasure) {
        return 1
      }
      return -1
    })
    ?.sort(
      (a, b) => b.supplierContractLineNumber - a.supplierContractLineNumber
    )
    ?.sort((a, b) => b.supplierContractNumber - a.supplierContractNumber)
    ?.sort((a, b) => a.pickupAt - b.pickupAt)

  const reducer = sorted.reduce(function (acc, cur) {
    const title = cur.title
    const found = acc?.find(function (x) {
      return x.title === title
    })
    if (found) {
      const newArr = acc.filter(object => {
        return object.title !== found.title
      })
      acc = newArr
      let adjustmentObj = {}
      if (!found.unitOfMeasure && found.pickupAt === cur.pickupAt) {
        adjustmentObj = {
          adjustment: {
            ...found
          }
        }
        acc.push({ ...cur, ...adjustmentObj })
      } else if (found.pickupAt === cur.pickupAt) {
        acc.push({ ...found, ...cur })
      }
    } else {
      acc.push(cur)
    }
    return acc
  }, [])

  return reducer
}
export const getSecureDocumentUrl = async documentUrl => {
  const { data } = await axios.get(documentUrl)
  return data.secureUrl
}

// Mocked services
// =======================================================================

export const getMockedUser = async id => {
  return {
    email: id,
    customers: ['Cargill']
  }
}

export const getMockedScheduleData = async () => {
  await delay(500)
  const createData = index => {
    return INITIAL_EVENTS
  }
  return createData()
}

export const getMockedContractGridData = async (
  page,
  pageSize,
  sort,
  filter
) => {
  await delay(500)
  const createData = index => {
    return {
      _id: index,
      documentNumber: faker.datatype.string(7),
      loadNumber: faker.datatype.number(100),
      productDescription: 'Hog Fat - Bacon Fat for Feed Use',
      dropoffLocation: 'ROME',
      documentDate: moment(faker.date.soon()).format('YYYY-MM-DD'),
      details: [
        {
          _id: 1235,
          description: 'Hog Fat',
          unitPrice: 0.4825,
          quantity: faker.datatype.number(100),
          unit: 'LB',
          loadNumber: faker.datatype.number(100),
          customerPurchaseOrderNumber: 'PC-190-WER-1',
          dropoffLocation: 'Rome',
          dropoffAt: moment(faker.date.soon()).format('YYYY-MM-DD'),
          eta: '1PM',
          confirmed: true
        },
        {
          _id: 1239,
          description: 'Hog Fat',
          unitPrice: 0.4825,
          quantity: faker.datatype.number(100),
          unit: 'LB',
          loadNumber: faker.datatype.number(100),
          customerPurchaseOrderNumber: 'PC-190-WER-1',
          dropoffLocation: 'Rome',
          dropoffAt: moment(faker.date.soon()).format('YYYY-MM-DD'),
          eta: '1PM',
          confirmed: true
        },
        {
          _id: 1238,
          description: 'Hog Fat',
          unitPrice: 0.4825,
          quantity: faker.datatype.number(100),
          unit: 'LB',
          loadNumber: faker.datatype.number(100),
          customerPurchaseOrderNumber: 'PC-190-WER-1',
          dropoffLocation: 'Rome',
          dropoffAt: moment(faker.date.soon()).format('YYYY-MM-DD'),
          eta: '1PM',
          confirmed: false
        },
        {
          _id: 12300,
          description: 'Hog Fat',
          unitPrice: 0.4825,
          quantity: faker.datatype.number(100),
          unit: 'LB',
          loadNumber: faker.datatype.number(100),
          customerPurchaseOrderNumber: 'PC-190-WER-1',
          dropoffLocation: 'Rome',
          dropoffAt: moment(faker.date.soon()).format('YYYY-MM-DD'),
          eta: '1PM',
          confirmed: false
        },
        {
          _id: 123123,
          description: 'Hog Fat',
          unitPrice: 0.4825,
          quantity: faker.datatype.number(100),
          unit: 'LB',
          loadNumber: faker.datatype.number(100),
          customerPurchaseOrderNumber: 'PC-190-WER-1',
          dropoffLocation: 'Rome',
          dropoffAt: moment(faker.date.soon()).format('YYYY-MM-DD'),
          eta: '1PM',
          confirmed: true
        }
      ]
    }
  }
  return {
    docs: Array.from({ length: pageSize }, (v, i) => createData(i)),
    totalDocs: 100
  }
}

export const getMockedClients = async pageSize => {
  const nameOptions = ['Cargill', 'Company A', 'Company B']

  const createData = index => {
    return {
      _id: index,
      img: faker.image.business(),
      name: nameOptions[randIndex(nameOptions)],
      clientNumber: faker.datatype.number(999999),
      clientType: index % 2 ? 'customer' : 'supplier'
    }
  }
  return {
    docs: Array.from({ length: pageSize }, (v, i) => createData(i)),
    totalDocs: 100
  }
}

export const getMockedClientsData = async (page, pageSize, sort, filter) => {
  const createData = index => {
    return {
      _id: index,
      img: faker.image.business(),
      name: faker.name.firstName(),
      clientNumber: faker.datatype.number(999999),
      clientType: index % 2 ? 'customer' : 'supplier'
    }
  }
  return {
    docs: Array.from({ length: pageSize }, (v, i) => createData(i)),
    totalDocs: 100
  }
}

export const getMockedUsersData = async (page, pageSize, sort, filter) => {
  const createData = index => {
    return {
      userId: index,
      email: faker.internet.email(),
      clients: ['Cargill', 'Company A']
    }
  }

  return {
    docs: Array.from({ length: pageSize }, (v, i) => createData(i)),
    totalDocs: 100
  }
}

export const getMockedInvoiceData = async (page, pageSize, sort, filter) => {
  await delay(500)
  const createData = index => {
    return {
      _id: index,
      documentNumber: faker.datatype.string(7),
      postingDate: moment(faker.date.past()).format('YYYY-MM-DD'),
      dueDate:
        index < 2
          ? moment(faker.date.past()).format('YYYY-MM-DD')
          : moment(faker.date.soon()).format('YYYY-MM-DD'),
      amount: faker.finance.amount(1000, 10000, 2, '$'),
      remaining: '0.00'
    }
  }
  return {
    docs: Array.from({ length: pageSize }, (v, i) => createData(i)),
    totalDocs: 100
  }
}
