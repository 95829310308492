import { Box, Container, Typography } from '@mui/material'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <section>
      <Container maxWidth="sm">
        <Box pt={6} pb={10} textAlign="center">
          <img src="/images/error.svg" alt="Error" />
          <Typography variant="h3" color="primary">
            Whoops!
          </Typography>
          <Typography variant="h3" component="h2" gutterBottom={true}>
            Something went wrong!
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            We're sorry, our wires must have been crossed.
          </Typography>
          <Box mt={3}>
            <Button
              onClick={() => navigate('/')}
              variant="contained"
              color="primary"
              to="/">
              Return to the homepage
            </Button>
          </Box>
        </Box>
      </Container>
    </section>
  )
}
