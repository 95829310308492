import { Grid } from '@mui/material'
import React from 'react'
import ClientsDataTable from '../components/ClientsDataTable'
import { getClientsGridData } from '../services'
import { clientsColumn } from '../data/clientData'
import Spacer from '../components/Spacer'
import MobileTitle from '../components/MobileTitle'
import { useSetRecoilState } from 'recoil'
import { selectedEventAtom } from '../recoil/schedule/atom'

const ClientsPage = () => {
  const resetEvent = useSetRecoilState(selectedEventAtom)
  React.useEffect(() => {
    resetEvent({})
  }, [])

  return (
    <Grid spacing={4} container>
      <MobileTitle title="Clients" />
      <Grid mb={12} item xs={12}>
        <ClientsDataTable
          service={getClientsGridData}
          columns={clientsColumn}
        />
      </Grid>
      <Spacer m={6} />
    </Grid>
  )
}

export default ClientsPage
