import moment from 'moment'

export const toCurrency = value =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(value)

export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const randIndex = arr => Math.floor(Math.random() * arr.length)

export const groupBy = (arr, property) => {
  return arr.reduce((acc, cur) => {
    acc[cur[property]] = [...(acc[cur[property]] || []), cur]
    return acc
  }, {})
}

export const checkSingleClientUser = response => {
  if (
    response?.isAdmin ||
    (response?.customers?.length || 0) + (response?.suppliers?.length || 0) > 1
  ) {
    return false
  }
  return true
}

export const isToday = date => {
  return moment().isSame(date, 'day')
}

export const standardDateFormat = date => moment(date).format('MM/DD/YYYY')

export const handleAxiosError = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response?.data?.message) {
      return error.response?.data?.message
    } else {
      return `${error.response.status} ${error.response.statusText}`
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request?.message
  } else {
    // Something happened in setting up the request that triggered an Error
    return error?.message
  }
}
