import { createTheme } from '@mui/material/styles'

const palette = {
  mode: 'light',
  primary: {
    main: '#1991EB',
    light: 'rgba(69, 146, 235, 0.25)'
  },
  border: '#E0E0E0',
  secondary: {
    main: '#101C2A'
  },
  text: {
    primary: '#212121',
    secondary: '#1E90FF',
    gray: '#585858',
    gray2: '#696974'
  },
  success: {
    main: '#28BC36',
    contrastText: '#fff'
  },
  warning: {
    main: '#EE9A26'
  },
  error: {
    main: '#FC5A5A',
    contrastText: '#fff'
  },
  info: {
    main: '#DFF1FF'
  },
  background: {
    paper: '#fff',
    default: '#FAFAFC'
  },
  lightGray: '#CFD2D4'
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mdsm: 900,
      md: 1050,
      lg: 1200,
      desktop: 1360,
      xl: 1536
    }
  },
  typography: {
    fontFamily: '"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 600
    },
    caption: {
      fontSize: '.8125rem'
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em'
    },
    body2: {
      fontSize: '.75rem'
    }
  },
  palette: { ...palette },
  shape: {
    borderRadius: 4
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          color: '#535875',
          fontSize: 14
        },
        root: {
          background: palette.background.paper
        },
        cell: {
          fontSize: 14
        },
        cellContent: {
          color: '#0F1222',
          fontSize: 14
        },
        columnSeparator: {
          display: 'none'
        }
      }
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        borderRadius: 3
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 34
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          textAlign: 'left'
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          height: 12,
          minWidth: 12,
          borderRadius: 6
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          justifyContent: 'center'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          textAlign: 'left'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 6.03243px 12.0649px rgba(0, 0, 0, 0.02)'
        },
        elevation4: {
          boxShadow: 'inset 0px -0.502703px 0px rgba(0, 0, 0, 0.1)'
        },
        rounded: {
          borderRadius: 3
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: 16
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          width: 2,
          borderColor: '#E8ECEF',
          marginRight: -2
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '32px 24px 0px 24px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
          fontSize: 18
        },
        secondary: {
          fontSize: 16
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#263238',
          fontSize: 16
        }
      }
    }
  }
})

export const snackBarSuccess = {
  '& .SnackbarItem-message': {
    ...theme.typography.body1,
    fontFamily: 'Roboto'
  }
}

export const snackBarFailure = {
  // to change colors use
  // '& .SnackbarContent-root': {
  // },
  '& .SnackbarItem-message': {
    ...theme.typography.body1,
    fontFamily: 'Roboto'
  }
}

export const snackBarInfo = {
  '& .SnackbarItem-message': {
    ...theme.typography.body1,
    fontFamily: 'Roboto'
  }
}

export default theme
