import * as React from 'react'
import { Controller } from 'react-hook-form'
import MuiAutoComplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

const Autocomplete = ({
  control,
  errors,
  label,
  options,
  margin,
  name,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <MuiAutoComplete
          {...props}
          {...field}
          multiple
          disablePortal
          // filterSelectedOptions
          // filterOptions={filterOptions}
          id="tags-standard"
          // filterSelectedOptions
          options={options}
          onChange={(_e, data) => {
            field?.onChange(data)
          }}
          sx={{
            '& .MuiAutocomplete-option': {
              textAlign: 'left'
            }
          }}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              margin={margin}
              label={label}
              error={!!errors?.clients}
              helperText={errors?.clients?.message}
              variant="outlined"
              InputProps={{
                ...params.InputProps
              }}
            />
          )}
        />
      )}
    />
  )
}

Autocomplete.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  margin: PropTypes.string,
  name: PropTypes.string
}

export default Autocomplete
