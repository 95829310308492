import {
  Grid,
  Table,
  TableRow,
  TableFooter,
  TableContainer,
  TablePagination,
  TableBody,
  Button,
  Typography
} from '@mui/material'
import React from 'react'
import {
  getCustomerNotifications,
  updateCustomerNotifications,
  updateSupplierNotifications
} from '../services'
import MobileTitle from '../components/MobileTitle'
import { useLocation, useParams } from 'react-router-dom'
import { getSupplierNotifications } from '../services/index'
import NotificationComponent from '../components/NotificationComponent'
import { useSnackbar } from 'notistack'
import { snackBarFailure } from '../theme'
import { LoadingWrapper } from '../components/LoadingWrapper'
import { handleAxiosError } from '../utils'
import { ReactComponent as SearchSvg } from '../assets/search.svg'
import { logError } from '../services/sentry'
import usePrevious from '../hooks'

const NotificationsPage = () => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [refresh, setRefresh] = React.useState(false)
  const previousRefresh = usePrevious(refresh)
  const service = pathname.includes('Customers')
    ? getCustomerNotifications
    : getSupplierNotifications

  const [data, setData] = React.useState({
    docs: [],
    pages: {},
    limit: 25,
    loading: true,
    page: 0,
    totalDocs: 0
  })

  React.useEffect(() => {
    // utilize caching to speed up pagination support
    if (data.pages[data.page] && previousRefresh === refresh) {
      setData(prev => ({ ...prev, docs: data.pages[data.page] }))
      return
    }

    let active = true
    ;(async () => {
      setData(prev => ({ ...prev, loading: true }))
      const results = await service(id, data.page + 1, data.limit)
      if (!active) {
        return
      }

      const pages = Object.assign({}, data.pages)
      pages[data.page] = results.docs
      setData(prev => ({
        ...prev,
        loading: false,
        docs: results.docs,
        totalDocs: results.totalDocs,
        pages
      }))
    })().catch(error => {
      const errorMessage = handleAxiosError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
      logError(errorMessage)
    })
    return () => {
      active = false
    }
  }, [data.page, refresh, id, data.limit, data.pages, service])

  const markAllRead = async () => {
    try {
      const modifiedArray = data.docs
        ?.filter(x => !x?.isAcknowledged)
        .map(x => ({
          ...x,
          isAcknowledged: true
        }))
      if (pathname.includes('Customers')) {
        await updateCustomerNotifications(modifiedArray)
      } else {
        await updateSupplierNotifications(modifiedArray)
      }
      setRefresh(prev => !prev)
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        sx: snackBarFailure
      })
    }
  }

  return (
    <Grid spacing={4} container>
      <MobileTitle title="Notifications" />
      <LoadingWrapper visible={data?.loading}>
        {data?.docs?.length ? (
          <>
            <Grid item mt={4} textAlign="right" xs={12}>
              {data?.docs?.filter(x => !x?.isAcknowledged)?.length !== 0 && (
                <Button onClick={markAllRead} variant="text">
                  Mark as Read
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table>
                  <TableBody>
                    {data?.docs?.map((x, i) => (
                      <TableRow key={i}>
                        <NotificationComponent component="td" {...x} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={data?.totalDocs}
                      rowsPerPage={data?.limit}
                      page={data?.page}
                      onRowsPerPageChange={event =>
                        setData(prev => ({
                          ...prev,
                          limit: event.target.value
                        }))
                      }
                      onPageChange={(event, page) =>
                        setData(prev => ({ ...prev, page }))
                      }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </>
        ) : !data?.docs?.length && !data?.loading ? (
          <Grid item mt={12} xs={12}>
            <SearchSvg />
            <Typography gutterBottom sx={{ fontWeight: 'bold' }} variant="h4">
              No Notifications found.
            </Typography>
          </Grid>
        ) : null}
      </LoadingWrapper>
    </Grid>
  )
}

export default NotificationsPage
