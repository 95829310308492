import { parse, format } from 'date-fns'

const dateOnlyFormatter = params => {
  // Convert yyyy-MM-dd' to  'MM/dd/yyyy'
  return params.value
    ? format(parse(params.value, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    : params.value
}

export { dateOnlyFormatter }
