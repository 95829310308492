import React from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { PropTypes } from 'prop-types'

const MobileTitle = ({ title }) => {
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  if (!desktop) {
    return (
      <Grid mt={2} item xs={12}>
        <Typography align="left" variant="h4">
          {title}
        </Typography>
      </Grid>
    )
  } else {
    return null
  }
}

MobileTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default MobileTitle
