import React from 'react'

export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = React.useRef(false)

  React.useEffect(() => {
    if (didMountRef.current) {
      return fn()
    }
    didMountRef.current = true
  }, inputs)
}
