/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import React from 'react'
import { TraderContactBar } from '../components/TraderContact'
import { CustomerLoadInformation } from '../components/CustomerLoadInformation'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useTheme } from '@mui/styles'
import Schedule from '../components/Schedule'
import {
  eventNotFoundAtom,
  preSearchEventAtom,
  selectedEventAtom
} from '../recoil/schedule/atom'
import moment from 'moment'
import MobileTitle from '../components/MobileTitle'
import { SupplierLoadInformation } from '../components/SupplierLoadInformation'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { getCustomer, getSupplier } from '../services'
import { useSnackbar } from 'notistack'
import { handleAxiosError } from '../utils'
import { snackBarFailure } from '../theme'
import { logError } from '../services/sentry'
import {
  clientDataAtom,
  locationFilterAtom,
  locationListAtom,
  scheduleAutocompleteFilterAtom,
  scheduleAutocompleteOptionsAtom
} from '../recoil/data'

const SchedulePage = () => {
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('xl'))
  const calendarRef = React.useRef()
  const { pathname } = useLocation()
  const [clientData, setClientData] = useRecoilState(clientDataAtom)
  const [searchParams, setSearchParams] = useSearchParams()
  const documentNumber = searchParams.get('documentNumber')
  const lineNumber = searchParams.get('lineNumber')
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedEvent, setSelectedEvent] = useRecoilState(selectedEventAtom)
  const setNotFound = useSetRecoilState(eventNotFoundAtom)
  const [preSearchEvent, setPreSearchEvent] = useRecoilState(preSearchEventAtom)
  const [autocompleteOptions, setAutocompleteOptions] = useRecoilState(
    scheduleAutocompleteOptionsAtom
  )
  const [autocompleteFilter, setAutocompleteFilter] = useRecoilState(
    scheduleAutocompleteFilterAtom
  )
  const locationList = useRecoilValue(locationListAtom)
  const [locationFilter, setLocationFilter] = useRecoilState(locationFilterAtom)
  const [loading, setLoading] = React.useState(false)
  const userType = pathname.includes('Suppliers') ? 'supplier' : 'customer'

  const handleReceivedData = () => {
    let calendarApi = calendarRef?.current?.getApi()
    const events = calendarApi?.getEvents()
    const formatted = events?.map(x => ({ ...x?._def, ...x?._instance }))
    const sorted = formatted
      ?.sort((a, b) => {
        if (a.extendedProps?.unitOfMeasure) {
          return 1
        }
        return -1
      })
      ?.sort(
        (a, b) =>
          b.extendedProps[`${userType}ContractLineNumber`] -
          a.extendedProps[`${userType}ContractLineNumber`]
      )
      ?.sort(
        (a, b) =>
          b.extendedProps[`${userType}ContractNumber`] -
          a.extendedProps[`${userType}ContractNumber`]
      )
      ?.sort((a, b) => a.range.end - b.range.end)
    const uniqueArray = [
      ...new Map(sorted?.map(item => [item['title'], item])).values()
    ]
    setAutocompleteOptions(uniqueArray)
    setAutocompleteFilter(uniqueArray)
  }

  const selectEvent = e => {
    const { documentNumber, lineNumber } = e
    let calendarApi = calendarRef.current?.getApi()
    const events = calendarApi?.getEvents()
    const formatted = events?.map(x => ({ ...x?._def, ...x?._instance }))
    let filtered
    if (pathname.includes('Customers')) {
      filtered =
        documentNumber &&
        lineNumber &&
        formatted?.filter(
          x =>
            x.extendedProps.customerContractNumber == documentNumber &&
            x.extendedProps.customerContractLineNumber == lineNumber
        )
    } else {
      filtered =
        documentNumber &&
        lineNumber &&
        formatted?.filter(
          x =>
            x.extendedProps.supplierContractNumber == documentNumber &&
            x.extendedProps.supplierContractLineNumber == lineNumber
        )
    }

    if (filtered?.length === 1) {
      const event = filtered?.[0]
      setSelectedEvent(event)
      const date = moment(event?.range?.end).format('YYYY-MM-DD')
      calendarApi.gotoDate(date)
      calendarApi.select(date)
      setNotFound(false)
    } else {
      setNotFound(true)
    }
  }

  React.useEffect(() => {
    if (documentNumber && autocompleteOptions?.length && lineNumber) {
      selectEvent({ documentNumber, lineNumber })
      searchParams.delete('documentNumber')
      searchParams.delete('lineNumber')
      setSearchParams({ documentNumber: '', lineNumber: '' }, { replace: true })
    }
  }, [autocompleteOptions])

  React.useEffect(() => {
    if (preSearchEvent) {
      selectEvent(preSearchEvent)
      setPreSearchEvent(null)
    }
  }, [preSearchEvent])

  React.useEffect(() => {
    if (locationList.length && !locationFilter.length) {
      setLocationFilter(locationList)
    }
  }, [locationList])

  const handleAutoComplete = e => {
    if (e) {
      let calendarApi = calendarRef?.current?.getApi()
      setSelectedEvent(e)
      const date = moment(e?.range?.end).format('YYYY-MM-DD')
      calendarApi.gotoDate(date)
      calendarApi.select(date)
      setNotFound(false)
    }
  }

  const handleLocationFilter = event => {
    const {
      target: { value }
    } = event
    const definedFilter = typeof value === 'string' ? value.split(',') : value
    setLocationFilter(
      // On autofill we get a stringified value.
      definedFilter
    )
  }

  React.useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        if (pathname.includes('Suppliers')) {
          const response = await getSupplier(id)
          await setClientData(response)
        } else if (pathname.includes('Customers')) {
          const response = await getCustomer(id)
          await setClientData(response)
        }
        setLoading(false)
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
        setLoading(false)
      }
    }
    if (!clientData) {
      getData()
    }
    if (selectedEvent?.extendedProps) {
      const handle = async () => {
        let calendarApi = await calendarRef?.current?.getApi()
        await calendarApi?.gotoDate(
          selectedEvent?.extendedProps?.dropoffAt ||
            selectedEvent?.extendedProps?.pickupAt
        )
        await calendarApi?.select(
          selectedEvent?.extendedProps?.dropoffAt ||
            selectedEvent?.extendedProps?.pickupAt
        )
      }
      handle()
    }
  }, [])

  return (
    <Grid spacing={4} container>
      <MobileTitle title="Schedule" />
      {!desktop && (
        <Grid item xl={9} xs={12}>
          <TraderContactBar loading={loading} {...clientData?.trader} />
        </Grid>
      )}
      <Grid md={12} xl={3} item xs={12}>
        <Autocomplete
          disablePortal
          sx={{
            '& .MuiFormControl-root': {
              marginTop: 0
            }
          }}
          getOptionLabel={option => option?.title}
          loading={!autocompleteOptions}
          renderInput={params => (
            <TextField
              margin="normal"
              {...params}
              variant="outlined"
              label="order #"
              placeholder="search by order number"
            />
          )}
          onChange={(event, newValue) => {
            handleAutoComplete(newValue)
          }}
          options={autocompleteFilter ? autocompleteFilter : []}
        />
      </Grid>
      {desktop && (
        <Grid item xl={9} xs={12}>
          <TraderContactBar {...clientData?.trader} />
        </Grid>
      )}
      {locationList?.length > 1 && (
        <>
          <Grid md={12} xl={3} item xs={12}>
            <TextField
              fullWidth
              value={locationFilter}
              select
              label="location"
              SelectProps={{
                multiple: true,
                renderValue: selected => selected.join(', ')
              }}
              onChange={handleLocationFilter}>
              {locationList ? (
                locationList?.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={locationFilter.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))
              ) : (
                <Typography>Loading...</Typography>
              )}
            </TextField>
          </Grid>
          <Grid item md={0} xl={9}></Grid>
        </>
      )}
      <Grid xl={3} item xs={12}>
        {pathname.includes('Suppliers') ? (
          <SupplierLoadInformation />
        ) : (
          <CustomerLoadInformation />
        )}
      </Grid>
      <Grid item xl={9} xs={12}>
        <Schedule
          {...{
            handleReceivedData,
            calendarRef
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SchedulePage
