export const invoiceColumns = [
  {
    field: 'documentNumber',
    headerName: 'Invoice Number'
  },
  {
    field: 'postingAt',
    headerName: 'Posting Date'
  },
  {
    field: 'dueAt',
    headerName: 'Due Date'
  },
  {
    field: 'totalBalance',
    headerName: 'Amount'
  },
  {
    field: 'balanceDue',
    headerName: 'Remaining'
  },
  {
    field: 'pdf',
    headerName: ''
  }
]
