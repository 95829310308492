import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import theme from './theme'
import { RecoilRoot } from 'recoil'
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'
import { SnackbarProvider } from 'notistack'

const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

ReactDOM.render(
  <RecoilRoot>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </MsalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </RecoilRoot>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
