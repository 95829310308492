import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import BalanceCard from '../components/BalanceCard'
import InvoiceDataTable from '../components/InvoiceDataTable'
import Link from '../components/Link'
import { LoadingWrapper } from '../components/LoadingWrapper'
import MobileTitle from '../components/MobileTitle'
import Spacer from '../components/Spacer'
import TraderContactBar from '../components/TraderContact'
import { invoiceColumns } from '../data/invoiceData'
import { clientDataAtom } from '../recoil/data'
import { getCustomer, getCustomerInvoiceData, getSupplier } from '../services'
import { logError } from '../services/sentry'
import { snackBarFailure } from '../theme'
import { handleAxiosError } from '../utils'

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(4, 3, 0, 3)
  },
  cardContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '& h6': {
      fontSize: 20,
      fontWeight: 600,
      color: '#0F1222'
    },
    '& .date': {
      fontFamily: 'Roboto',
      color: '#696974',
      fontSize: 14,
      letterSpacing: 0.1
    }
  },
  subtitle: {
    color: '#696974'
  },
  caption: {
    color: '#696974',
    fontWeight: 600
  },
  address: {
    marginLeft: theme.spacing(1),
    color: '#696974'
  }
}))

const InvoicesPage = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const [loading, setLoading] = React.useState(false)
  const [clientData, setClientData] = useRecoilState(clientDataAtom)
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        if (pathname.includes('Suppliers')) {
          const response = await getSupplier(id)
          await setClientData(response)
        } else if (pathname.includes('Customers')) {
          const response = await getCustomer(id)
          await setClientData(response)
        }
        setLoading(false)
      } catch (error) {
        const errorMessage = handleAxiosError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          sx: snackBarFailure
        })
        logError(error)
        setLoading(false)
      }
    }
    if (!clientData) {
      getData()
    }
  }, [])

  return (
    <Grid spacing={4} container>
      <MobileTitle title="Invoices" />
      <Grid item xs={12}>
        <TraderContactBar loading={loading} {...clientData?.trader} />
      </Grid>
      <Grid position="relative" item lg={6} xs={12}>
        <LoadingWrapper visible={loading}>
          <BalanceCard
            totalBalance={clientData?.totalBalance}
            balanceDue={clientData?.balanceDue}
          />
        </LoadingWrapper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box>
              <Typography align="left" variant="h6">
                Payment Information
              </Typography>
              <Typography color="text.gray2" align="left">
                payments may be made out to WWS, Inc. or WWS Trading, for
                questions, please contact Jill Schmidt at{' '}
                <Link color="text.gray2" href={`mailto:jill@wwstrading.com`}>
                  {' '}
                  jill@wwstrading.com{' '}
                </Link>
                or{' '}
                <Link color="text.gray2" href={`tel:+952-548-9323`}>
                  (952) 548-9323.
                </Link>
              </Typography>
            </Box>
            <Box mt={2} display="flex">
              <Box display="flex">
                <Typography className={classes.caption} align="left">
                  Please Remit by ACH To:
                </Typography>
                <Typography align="left" className={classes.address}>
                  Venture Bank <br /> ABA: 091017196 <br /> Account: 015545
                </Typography>
              </Box>
              <Box ml={8} display="flex">
                <Typography className={classes.caption} align="left">
                  Or By Check to:
                </Typography>
                <Typography align="left" className={classes.address}>
                  VB BOX 114 <br /> PO BOX 9202 Minneapolis, MN <br />{' '}
                  55480-9202
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Spacer m={2} />
      <Grid mb={12} item xs={12}>
        <InvoiceDataTable
          service={getCustomerInvoiceData}
          columns={invoiceColumns}
        />
      </Grid>
    </Grid>
  )
}

export default InvoicesPage
